<!--This page will show when the entered url is invalid -->
<!-- for better understanding about  this have a look on router.js file -->
<template>
    <div class="NotFound pb-5">
        <div class="col-lg-6 mx-auto">
            <img src="../assets/NotFound.jpg" alt="Not Found" class="notfoundimg">
            <h1 class="proximanova-bold mt-4 fontsize30">The Requested URL is not found</h1>
            <button class="NotFoundBtn icon-scroll proximanova white mt-4" @click="goToHome">Go to Home</button>
        </div>
        
    </div>
</template>

<script>
import router from '../router.js'
import { EventBus } from '@/main.js';
export default{
    name: 'NotFound',
    methods : {
        goToHome(){
            EventBus.$emit('setActivename', 'home')
            sessionStorage.setItem("activeSection", 'home')
            router.push({
                name : 'home'
            })
        }
    }
}
</script>
<style scoped>
.NotFound{
    height: 100vh;
    padding-top: 8%;
}
.notfoundimg{
        width: 150px;
    }
@media screen and (max-width : 1000px) {
    .NotFound{
        padding-top: 20%;
    }
    .notfoundimg{
        width: 100px;
    }
}
</style>