<template>
    <div class="footer pt-4 pb-4">
        <footer class="container py-5">
            <div class="d-lg-flex d-md-flex d-sm-flex">
                <div class="col-lg-3 no-gutters col-md-3 d-flex flex-column text-left mb-2" @click="NavHomePage">
                    <img src="../assets/logo_cognaxtech_white.png" class="img-fluid footerlogo mx-auto" alt="Logo">
                </div>
                <div class="col-lg-3 col-md-4 col text-left mt-3 mt-md-1">
                    <div class="d-flex flex-column">
                        <span class="proximanova-bold fontsize16 white pointer">Explore Cognaxtech</span>
                        <span class="proximanova-light fontsize16 white60 linkover mt-2 pointer" @click="NavToCourse('IOT')">Internet Of Things </span>
                        <span class="proximanova-light fontsize16 white60 linkover mt-2 pointer" @click="NavToCourse('WebApp')">Web Application Development</span>
                        <span class="proximanova-light fontsize16 white60 linkover mt-2 pointer" @click="NavToCourse('Business')">Business Technology Consulting</span>
                        <span class="proximanova-light fontsize16 white60 linkover mt-2 pointer" @click="NavToCourse('CloudComputing')">Cloud Computing Application</span>
                        <span class="proximanova-light fontsize16 white60 linkover mt-2 pointer" @click="NavToCourse('IOS')">IOS Application Development</span>
                        <span class="proximanova-light fontsize16 white60 linkover mt-2 pointer" @click="NavToCourse('Android')">Android Application Development</span>
                        <span class="proximanova-light fontsize16 white60 linkover mt-2 pointer" @click="NavToCourse('Hybrid')">Hybrid Application Development</span>
                        <span class="proximanova-light fontsize16 white60 linkover mt-2 pointer" @click="NavToCourse('Recruiting')">Recruiting</span>
                    </div>
                </div>
                <div class="col-lg-3 no-gutters col-md-2 text-left mt-3 mt-md-1">
                    <div class="d-flex flex-column">
                        <span class="proximanova-bold fontsize16 white pointer">Resources</span>
                        <span class="proximanova-light fontsize16 linkover white60 mt-2 pointer" @click="NavContactUs()">Contact Us </span>
                        <span class="proximanova-light fontsize16 linkover white60 mt-2 pointer" @click="openTermsAndConditions">Terms of Use</span>
                        <span class="proximanova-light fontsize16 linkover white60 mt-2 pointer" @click="moveToPrivacy">Privacy and policies</span>
                    </div>
                </div>
                <div class="col-lg-3 no-gutters col-md-3 text-left mt-3 mt-md-1">
                    <div class="d-flex flex-column">
                        <span class="proximanova-bold fontsize16 white pointer">Follow us on</span>
                        <div class="d-flex mt-2">
                            <a href="" target="_blank" class="websitelinks" rel="noopener nofollow">
                                <img src="../assets/linkedin_black.svg" class="img-fluid socialmediaLogos" alt="LinkedIn Logo">
                                <span class="proximanova-light fontsize16 white60 pointer linkover ml-2 my-auto">LinkedIn </span>
                            </a>
                        </div>
                        <div class="d-flex mt-3">
                            <a href="" target="_blank" class="websitelinks" rel="noopener nofollow">
                                <img src="../assets/twitter_black.png" class="img-fluid socialmediaLogos twitterLogo" alt="Twitter Logo">
                                <span class="proximanova-light fontsize16 white60 ml-2 linkover pointer my-auto">Twitter </span>
                            </a>
                        </div>
                        <div class="d-flex mt-3">
                            <a href="" target="_blank" class="websitelinks" rel="noopener nofollow">
                                <img src="../assets/facebook_black.svg" class="img-fluid socialmediaLogos" alt="Facebook Logo">
                                <span class="proximanova-light fontsize16 white60 ml-2 linkover pointer my-auto">Facebook </span>
                            </a>
                        </div>
                        <div class="d-flex mt-3">
                            <a href="" target="_blank" class="websitelinks" rel="noopener nofollow">
                                <img src="../assets/instagram_black.svg" class="img-fluid socialmediaLogos" alt="Instagram Logo">
                                <span class="proximanova-light fontsize16 white60 ml-2 linkover pointer my-auto">Instagram </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right mt-2">
                <span class="copyright pull-right ml-2 ">Cognax tech@2021 All rights reserved</span>
            </div>
        </footer>
    </div>
</template>
<script>
import router from '../router'
// import { EventBus } from '@/main.js';
export default {
    data() {
        return{

        }
    },
    methods : {
        NavContactUs : function() {
            router.push({
                name : 'contactUs'
            })
        },
        openTermsAndConditions: function () {   
            let routeData = this.$router.resolve({name: 'TermsConditions'});
            window.open(routeData.href, '_blank');
        },
        moveToPrivacy(){
            let routeData = this.$router.resolve({name: 'PrivacyPolicy'});
            window.open(routeData.href, '_blank');
        },
        NavToCourse(course){
            if(course === 'IOT') {
                router.push({
                    name : 'iot'
                })
            }else if(course === 'WebApp') {
                router.push({
                    name : 'webApp'
                })
            }else if(course === 'Business') {
                router.push({
                    name : 'businessTechnology'
                })
            }else if(course === 'CloudComputing') {
                router.push({
                    name : 'cloudComputing'
                })
            }else if(course === 'IOS') {
                router.push({
                    name : 'iosApplication'
                })
            }else if(course === 'Android') {
                router.push({
                    name : 'androidApplication'
                })
            }else if(course === 'Hybrid') {
                router.push({
                    name : 'hybridApplication'
                })
            }else if(course === 'Recruiting') {
                router.push({
                    name : 'recruiting'
                })
            }
        },
        NavHomePage : function() {
            if(this.$router.currentRoute.name === 'home') {
                window.scrollTo(0, 0)  
            }else{
            router.push({
                name : 'home'
            })
            }         
            
        },
    }
}
</script>
<style scoped>
.footerlogo{
    width: 130px;
}
    .copyright {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
        color: rgba(255, 255, 255, .6);
    }
    p{
        margin: 0 auto;
        color: white;
    }
    .scrollup{
        font-size: 16px;
        color: rgba(255, 255, 255, .6);
    }
    .linkover:hover{
        color: white !important;
    }
    .syntrinotxt:hover{
        text-decoration: none !important;
    }
    .socialmediaLogos {
        background: white;
        border-radius: 8px;
        height: 26px;
        width: 26px;
    }
    .twitterLogo{
        padding: 2px;
    }
    .syntrinologo{
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    @media screen and (max-width: 1020px) and (min-width: 767px) {
        .footersocial{
            position: absolute;
            bottom: 0;
        }
    }
    @media screen and (max-width: 1200px){
        .socialmediaLogos {
            height: 24px;
            width: 24px;
        }
        .footerlogo{
            width: 100px;
        }
    }
    @media (min-width: 1200px){
        .container{
            max-width: 84%;
        }
        .footersocial{
            position: absolute;
            bottom: 0;
        }
    }
</style>