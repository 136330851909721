<template>
<div>
    <div class="d-none d-md-block">
        <nav id="nav" class="navbar navbar-expand-md  navbar-hover navstyle justify-content-between ">
            <div class="navbar-brand ml-lg-5 ml-md-5 ml-sm-2 p-1 col-md-2 col-lg-1" href="#">
                <img src="../assets/logo_cognaxtech_white.png" id="logoIcon" v-if="!Isscrolled" alt="Logo" class="img-fluid" @click="NavHomePage">
            </div>
            <div class="collapse navbar-collapse px-5 ml-5 float-right" id="navbarHover">
                <ul class="navbar-nav">
                    <li @click="NavHomePage" class="d-flex mr-2" >
                        <span class="my-auto ml-2 proximanova-semibold colorwhite listItem">Home</span>
                    </li>
                    <li class="p-2 mr-2 nav-item dropdown d-flex ml-md-3">
                        <span href="#" data-toggle="collapse" data-target="#myNavbar" id="toggleMenuItem" class="navbar-toggle my-auto ml-2 proximanova-semibold colorwhite listItem servicesItem" @click="ServicesMenu">Services <img src="../assets/arrowdownwhite.svg" class="img-fluid ml-2" id="arrowDown" width="10px" alt="down arrow"></span>
                    </li>
                    <li class="p-2 mr-2 d-flex ml-md-3" @click="NavtoContactUs">
                        <span class="my-auto text-nowrap ml-2 proximanova-semibold colorwhite listItem">Contact Information</span>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="collapse navbar-collapse shadow" id="myNavbar">
            <div class="col-12 backgroundColorwhite">
                <div class="text-right pt-4">
                    <span class="closebtn shadow mr-4" data-toggle="collapse" data-target="#myNavbar">X</span>
                </div>
                <div class="d-flex col-lg-7 mx-auto pt-3 pb-3 mt-4 pb-5">
                    <span class="proximanova-semibold black fontsize18 mt-2">Services we are providing</span>
                    <ul class="borderleftblack pl-0 ml-5">
                        <li class="pb-2 p-1 pl-3 d-flex selectedserviceItem" 
                            v-for="(course, index) in listItems" :key="index"
                            @click="NavtoCourse(course)">
                            <span class="p-1 proximanova-semibold black fontsize14 text-wrap mb-1">{{course.courseName}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="d-block d-md-none">
        <div class="navstyle d-flex justify-content-between" id="smallNav">
            <div class="text-left p-2">
                <img src="../assets/logo_cognaxtech_small_white.png" id="smalllogoIcon" height="30px" class="my-auto mt-2 ml-3" alt="Logo">
            </div>
            <div class="d-flex">
                <img src="../assets/HamburgrMenu_white.svg" alt="toggle button" height="20px" data-toggle="collapse" data-target="#myNavbar1" id="toggleMenuItem1" class="my-auto mr-4 navbar-toggle listItem servicesItem">
            </div>
        </div>
        <div class="collapse navbar-collapse shadow" id="myNavbar1">
            <div class="col-12 backgroundColorwhite d-flex flex-column text-left">
                <div class="text-right pt-4">
                    <span class="closebtn shadow mr-4" data-toggle="collapse" data-target="#myNavbar1">x</span>
                </div>
                <div class=" pt-3 pb-3 mt-4 pb-5" >
                    <div><span class="proximanova-semibold black fontsize18 mt-2 ml-2" @click="NavHomePage">Home</span></div>
                    <div class="mt-2">
                        <span class="proximanova-semibold black fontsize18 mt-2 ml-2">Services <img src="../assets/arrowdown.svg" class="img-fluid ml-2" alt="arrow donw"></span>
                        <ul class="borderleftblack pl-0 ml-4">
                            <li class="pb-2 p-1 pl-3 d-flex selectedserviceItem" 
                                v-for="(course, index) in listItems" :key="index"
                                @click="NavtoCourse(course)">
                                <span class="p-1 proximanova-semibold black fontsize14 text-wrap mb-1">{{course.courseName}}</span>
                            </li>
                        </ul>
                    </div>
                    <div  class="mt-2"><span class="proximanova-semibold black fontsize18 mt-2 ml-2" @click="NavtoContactUs">Contact Information</span></div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import router from '../router'
import { EventBus } from '@/main.js';
export default {
    router,
    data() {
        return {
            activeSection : 'home',
            scrollPosition: null,
            startLearningModal : false,
            contactUsModal : false,
            coureName : 'Java full stack',
            listItems : [
                {courseName : 'Internet Of Things'},
                {courseName : 'Web Application Development'},
                {courseName : 'Business Technology Consulting'},
                {courseName : 'Cloud Computing Application'},
                {courseName : 'IOS Application Development'},
                {courseName : 'Android Application Development'},
                {courseName : 'Hybrid Application Development'},
                {courseName : 'Recruiting'}
            ],
            Isscrolled : false,
            toggleServicesMenu : false,
        }
    },
    components : {
    },
    mounted() {
        var that = this;
        let status = sessionStorage.getItem("activeSection")
        if(status !== undefined && status !== null) {
            this.activeSection = status
        }else{
            this.activeSection = 'home'
        }
        EventBus.$on('setActivename', (status) => {
            that.activeSection = status;
        })
        this.setNavStyles()
    },
    methods : {
        setNavStyles() {
            var myNav = document.getElementById("nav");
            var myNav2 = document.getElementById("smallNav")
            var listItems = document.getElementsByClassName("listItem")
            var logoimage = document.getElementById("logoIcon")
            var logoimage2 = document.getElementById("smalllogoIcon");
            var toggleIcon = document.getElementById("toggleMenuItem1")
            var arrowDownImage = document.getElementById("arrowDown")
            window.onscroll = function() {
            if (document.body.scrollTop >= 280 || document.documentElement.scrollTop >= 280) {
                myNav2.classList.add("changeColor");
                myNav.classList.add("changeColor");
                this.Isscrolled = false;
                logoimage.removeAttribute('src')
                logoimage.setAttribute("src", require('../assets/logo_cognaxtech.png')) 
                if(logoimage2){
                    logoimage2.removeAttribute('src')
                    logoimage2.setAttribute("src", require('../assets/logo_cognaxtech_small.png'))
                }
                if(toggleIcon){
                    toggleIcon.removeAttribute('src')
                    toggleIcon.setAttribute("src", require('../assets/HamburgrMenu.svg'))
                }
                arrowDownImage.removeAttribute('src')
                arrowDownImage.setAttribute('src', require('../assets/arrowdown.svg'))
                for(var i = 0; i < listItems.length; i++){
                listItems[i].classList.add("colorblack");
                }
            } else {
                myNav2.classList.remove("changeColor");
                myNav.classList.remove("changeColor");
                this.Isscrolled = true;
                logoimage.removeAttribute('src')
                logoimage.setAttribute("src", require('../assets/logo_cognaxtech_white.png')) 
                if(logoimage2){
                    logoimage2.removeAttribute('src')
                    logoimage2.setAttribute("src", require('../assets/logo_cognaxtech_small_white.png'))
                }
                if(toggleIcon){
                    toggleIcon.removeAttribute('src')
                    toggleIcon.setAttribute("src", require('../assets/HamburgrMenu_white.svg'))
                }
                arrowDownImage.removeAttribute('src')
                arrowDownImage.setAttribute('src', require('../assets/arrowdownwhite.svg'))
                for(var j = 0; j < listItems.length; j++){
                listItems[j].classList.remove("colorblack");
                }
            }
            }
        },
        ServicesMenu(){
            this.toggleServicesMenu = !this.toggleServicesMenu;
        },
        NavHomePage : function() {
            // if(this.toggleServicesMenu){
            //     let item = document.getElementById('toggleMenuItem')
            //     item.click()
            // }
            let item1 = document.getElementById('toggleMenuItem1')
            if(item1) {item1.click()}
            router.push({
                name : 'home'
            })
        },
        NavtoCourse(course){
            let item = document.getElementById('toggleMenuItem')
            let item1 = document.getElementById('toggleMenuItem1')
            if(item){ item.click() }
            if(item1) {item1.click() }
            if(course.courseName === 'Internet Of Things') {
                router.push({
                    name : 'iot'
                })
            }else if(course.courseName === 'Web Application Development') {
                router.push({
                    name : 'webApp'
                })
            }else if(course.courseName === 'Business Technology Consulting') {
                router.push({
                    name : 'businessTechnology'
                })
            }else if(course.courseName === 'Cloud Computing Application') {
                router.push({
                    name : 'cloudComputing'
                })
            }else if(course.courseName === 'IOS Application Development') {
                router.push({
                    name : 'iosApplication'
                })
            }else if(course.courseName === 'Android Application Development') {
                router.push({
                    name : 'androidApplication'
                })
            }else if(course.courseName === 'Hybrid Application Development') {
                router.push({
                    name : 'hybridApplication'
                })
            }else if(course.courseName === 'Recruiting') {
                router.push({
                    name : 'recruiting'
                })
            }
        },
        closeContactUs() {
            this.contactUsModal = false
        },
        NavtoContactUs() {
            let item1 = document.getElementById('toggleMenuItem1')
            if(item1) {item1.click() }
            router.push({
                name : 'contactUs'
            })
        },
    },
}
</script>

<style  scoped>
.navstyle {
    background: transparent;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 6%);
}
.colorwhite {
    color: white;
}
.colorblack {
    color: black !important;
}
.changeColor {
    background: white !important;
}
li {
    cursor: pointer;
}
.navbar {
    color: black;
}
/* .navbar::after{
    content: "";
    width: 100%;
    height: 4px;
    background: linear-gradient(-250deg, #ff0000 0%, #000000 100%);
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-size: cover;
    background-repeat: no-repeat;
} */
.dropdown-menu {
    background-color: white;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    font-size: 16px;
    margin-top: -5px;
    border: none;
    /* padding: 20px; */
    height: auto;
    width: 260px;
    overflow: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.dropdown-menu::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.dropdown-menu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.serviceItem:hover{
    background: linear-gradient(-250deg, #ff0000 0%, #000000 100%);
    color: white !important;
    border-radius: 16px;
}
.serviceItem:hover span{
    color: white !important;
}
.smalldevMenu{
    background-color: white;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    font-size: 16px;
    margin-top: -5px;
    border: none;
    border-radius: 16px;
    /* padding: 20px; */
    height: auto;
    width: 260px;
    overflow: auto;
    margin: 0 auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.smalldevMenu::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.smalldevMenu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* hover dropdown menus */
.navbar-hover .collapse ul li{position:relative;}
.navbar-hover .collapse ul li:hover> ul{
    display: flex;
    flex-direction: column;
    display:block;
    display: flex;
    color: black;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: none;
    border-radius: 15px;
    min-width:100%;
    margin-top: -5px;
}
.displaydiv{
    display: none;
    background: white;
    color: white;
    padding: 5%;
}
.selectedserviceItem:hover{
    border-left: 6px solid black;
    margin-left: -4px;
}
.closebtn{
    cursor: pointer;
    border: 1px solid #2c3e50;
    padding: 3px 6px;
    font-size: 14px;
    color: #2c3e50;
    border-radius: 16px;
}
.closebtn:hover{
    background-color: #2c3e50;
    color: white;
}
@media only screen and (min-width: 500px) and (max-width: 770px) {
    .navbar-nav {
        display: flex;
        flex-direction: inherit !important;
    }
    .dropdown-menu{
        margin-left: -100px !important;
    }
    /* .collapse:not(.show) {
        display: block !important;
    } */
}
@media only screen and (max-width: 500px) {
     .navbar-nav {
        display: flex;
        flex-direction: inherit !important;
    }
    .dropdown-menu{
        margin-left: -100px !important;
    }
    /* .collapse:not(.show) {
        display: block !important;
    } */
}
</style>