<template>
    <div class="">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">Acquiring the right talent is the most important key to growth</h1>
                    </div>
                    <p class="proximanova fontsize24 white body-copy text-left">{{description}}</p>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-center">
                        <div class="d-lg-flex">
                            <div class="col-lg-6 text-left my-auto">
                                <div class="borderbottomwhite width100 mb-3"></div>
                                <h3 class="fontsize28 white proximanova-semibold">Helping you find your job</h3>
                                <p class="proximanova-light fontsize18 white body-copy">{{helpingYouFindJobDescription}}</p>
                            </div>
                            <div class="col-lg-5">
                                <img src="../../assets/helpingYouFindJob.jpg" class="img-fluid mt-3 mb-3" alt="find job">
                            </div>
                        </div>
                        <div class="col-lg-8 pl-0 pr-0 mt-5 mx-auto">
                            <h3 class="fontsize24 white proximanova-semibold text-center">Find your perfect Job options with Cognax Tech</h3>
                            <div class="d-flex col-lg-12 pl-0 pr-0 mt-3 flex-wrap mx-auto justify-content-around">
                                <div class="col-lg-6 col-md-6 mt-3 d-flex flex-column">
                                    <img src="../../assets/startYoursearch.jpg" class="img-fluid mx-auto findJobsImage" alt="technologies">
                                    <h2 class="fontsize20 white proximanova-semibold mt-3">Start you search</h2>
                                    <span class="fontsize16 white proximanova-light mt-3">{{startYouSearch}}</span>
                                </div>
                                <div class="col-lg-6 col-md-6 mt-3 d-flex flex-column">
                                    <img src="../../assets/jobRecommendations.jpg" class="img-fluid mx-auto findJobsImage" alt="technologies">
                                    <h2 class="fontsize20 white proximanova-semibold mt-3">Get Job Recommendations</h2>
                                    <span class="fontsize16 white proximanova-light mt-2">{{jobRecommendations}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="container">
                <div class="d-flex">
                    <div class="backgroundColor6 width10 height100 borderadius16 ml-lg-3 mb-3 ml-2"></div>
                    <div class="div-block-41 d-flex flex-column text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                        <h3 class="fontsize28 white proximanova-bold">Our Specialisations</h3>
                        <p class="proximanova fontsize18 white body-copy text-left">{{ourSpecialisations}}</p>
                    </div>
                </div>
                <div class="col-lg-12 d-md-flex mt-5 pt-5">
                    <div class="col-lg-4 blogcard pointer" v-for="(item, index) in specialisationsList" :key="index">
                        <div class="blogImg">
                            <img src="../../assets/engineeringTechnology.jpg" v-if="index == 0" class="img-fluid " alt="artificial intelligence">
                            <img src="../../assets/education.jpg" v-if="index == 1" class="img-fluid " alt="artificial intelligence">
                            <img src="../../assets/professional.jpg" v-if="index == 2" class="img-fluid " alt="artificial intelligence">
                        </div>
                        <div class="blogTitle p-4 text-left">
                            <div class="borderleftwhite mb-4">
                                <span class="fontsize24px white proximanova-semibold p-2">{{item.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section3">
            <div class="container">
                <div class="d-flex flex-column col-12 pl-0 pr-0 flex-wrap">
                    <div class="borderbottomwhite width100 mb-3 mx-auto"></div>
                    <h3 class="fontsize28 white proximanova-bold">Recruiting Process at Cognax tech</h3>
                    <div class="d-flex mt-4" v-for="(step, index) in recritingProcess" :key="index">
                        <img src="../../assets/right_arrow.png" alt="right arrow" class="" width="30px" height="30px">
                        <div class="d-flex flex-column ml-3">
                            <h5 class="fontsize24 white proximanova-semibold text-left my-auto">{{step.title}}</h5>
                            <p class="fontsize16 white proximanova-white text-left mt-2">{{step.description}}</p>
                        </div>
                    </div>
                            
                        
                    <!-- <div class="col-lg-4 col-md-6 mt-3 text-left d-flex flex-column">
                        <div class="cardSection">
                            <img src="../../assets/recruitment.png" class="img-fluid" width="50px" height="50px" alt="technologies">
                        </div>
                        <h3 class="fontsize20 white proximanova-semibold mt-3">Recruitment</h3>
                        <span class="fontsize16 white proximanova-light mt-2">{{recruitment}}</span>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-3 text-left d-flex flex-column">
                        <div class="cardSection">
                            <img src="../../assets/selection.png" class="img-fluid" width="50px" height="50px" alt="technologies">
                        </div>
                        <h3 class="fontsize20 white proximanova-semibold mt-3">Selection</h3>
                        <span class="fontsize16 white proximanova-light mt-2">{{selection}}</span>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-3 text-left d-flex flex-column">
                        <div class="cardSection">
                            <img src="../../assets/placement.png" class="img-fluid" width="50px" height="50px" alt="technologies">
                        </div>
                        <h3 class="fontsize20 white proximanova-semibold mt-3">Placement</h3>
                        <span class="fontsize16 white proximanova-light mt-2">{{placement}}</span>
                    </div> -->
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="container">
                <div class="borderbottomwhite width100 mb-3 ml-3"></div>
                <div class="div-block-41 d-flex flex-column text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                    <h4 class="fontsize28 white proximanova-bold">Our Partners</h4>
                    <p class="proximanova fontsize18 white body-copy text-left mt-3">We are proud to work with all our partners to deliver our services.</p>
                    <p class="proximanova fontsize18 white body-copy text-left">{{ourPartners}}</p>
                </div>
                <div class="d-flex flex-wrap col-lg-8 pb-5 mx-auto justify-content-around">
                    <div v-for="(partner, index) in partners" :key="index" class="">
                        <div class="d-flex flex-column mt-4 ml-md-2">
                            <div class="tools mx-auto">
                                <img :src="partner.img" class="img-fluid" :alt="partner.title">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Recruiting | Staffing agency for Freshers & Working professionals",
            meta: [
                { name: 'description', content:  'Looking for a job? Cognaxtech is a recruiting company that helps to connect job seekers with the hiring employers. Find your right opportunity and get hired today!'},
                { name : 'keywords', content : 'Recruiting, Recruitment, Recruitment process, Hiring, Recruitment agency, hiring process, Recruitment companies, Professional Recruitment services, Recruiting experts.'}
            ]
        }
    }, 
    data() {
        return {
            description : "A recruiting agency or a staffing agency completes the hiring requirements of any company following the skills they require. Only the right individuals can help your business evolve, and only the right recruiting agency can assist you to employ them.",
            helpingYouFindJobDescription : "We’re accomplishing everything we can do to support you performing – and healthily and safely. We’re counting the latest jobs every day. And we’re creating it comfortable for you to work.",
            startYouSearch : "Send or Upload your resume with our professional's team. And have the hiring managers reach out to you via the online platform. Need not miss your dream job and always be on top in search.",
            jobRecommendations : "Get job recommendations that will match your skills, and you can stop worrying about missing the right job opportunity.",
            ourSpecialisations : "Cognax Tech has the talent in the subsequent areas to ensure top skills with our business. Explore cognax tech across our areas of mastery below",
            specialisationsList : [
                {title : "Engineering and Technology"},
                {title : "Education"},
                {title : "Professional & Industrial"}
            ],
            recruitment : "Recruitment involves sourcing and searching for prospective candidates and encouraging them to apply for open positions. This approach involves identifying your targeted market or opportunities and then following and inspiring them to join your company.",
            selection : "The selection process involves evaluating and selecting the best candidate for the job out of the list of prospective applicants. It is important to confirm that the candidate’s ability matches the job position during selection. In simple words, the choice is all about hiring a suitable candidate for the right job. This will deliver better efficiency and performance from employees.",
            placement : "Placement involves hiring and placing the right person for the suitable job role. The arrangement involves the relevant posting of the preferred candidate as per their qualification, skills and talent. For example, it creates sense to place a sales experience with the sales team instead of shifting them to the marketing team to fill in the posts. This will lead to a waste of human resources and result in an unsatisfied worker.",
            recritingProcess : [
                {title : 'Get Started Here', description : "Let cognaxtech know a little more about your company and your open positions."},
                {title : 'Take through the details', description : "A cognaxtech Pro will contact you to know more details and start recruiting on your behalf!"},
                {title : 'We find the candidates', description : "Your personally appointed cognaxtech Pro will review hundreds of candidates and select the most suitable candidates for your job role."},
                {title : 'We screen the candidates', description : "Cognaxtech will interview all the candidates who fit your job profile. We will also let them know important things about your company and get them curious about meeting your team."},
                {title : 'You review the candidates', description : "We will send you screened, eligible candidates for your review."},
                {title : 'We schedule interviews', description : "We will set up interviews for all the candidates who match your job role."},
                {title : 'An offer is made', description : "Your cognaxtech Pro will work with you to negotiate any offers and dates of joining."},
                {title : 'You make a successful hire', description : "You've made a successful hiring using cognaxtech!"},
                {title : 'New hire guarantee', description : "Cognaxtech stands behind every candidate we source for you as you grow your team. Our standard contracts for all candidates last 100 days above their joining date."},
            ],
            ourPartners : "Our trust in the value of partnership grows in every aspect of our work. Working with organizations whose values and aspirations align with our own helps us share knowledge and ideas, expand our reach, and achieve common goals.",
            partners : [
                {img : require('../../assets/partners1.png'), title : '1901 group'},
                {img : require('../../assets/partners2.png'), title : 'master card'},
                {img : require('../../assets/partners3.jpg'), title : 'first'},
                {img : require('../../assets/partners4.png'), title : 'lga'},
            ],
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 14% !important;
    color:white;
    /* background-image: linear-gradient(rgba(31, 31, 31, .85), rgba(31,31,31,.85)), url('../../assets/hybrid.jpg'); */
    background-image: url('../../assets/recruiting.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 100px 0;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.findJobsImage{
    width: 120px;
    height: 120px;
    border-radius: 50%;
}
.blogImg {
    z-index: 3;
    position: relative;
}
.blogTitle {
    position: relative;
    /* background-color: #1c2027 !important; */
    background-color: black;
    z-index: 4;
}
.blogcard:hover .blogTitle {
    margin-top: -25px;
    color: white;
    transition-duration: 0.3s;
    opacity: 1 !important;
}
.blogcard{
    transition-duration: 0.3s;
}
.blogcard:hover {
    opacity: .8;
}
/* section3 related styles */
.section3{
    padding-top: 4% !important;
    padding-bottom: 8% !important;
     background-color: #404a53;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.cardSection{
    width: 80px;
    height: 80px;
    padding: 15px;
    border-radius: 50%;
    background: white;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>