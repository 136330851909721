<template>
    <div class="edgeAi">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">Top Technology trends in the life insurance sector</h1>
                    </div>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description1}}</p>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description2}}</p>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description3}}</p>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container pl-3 pr-3">
                <div class="borderbottomwhite width100 mb-3"></div>
                <h3 class="fontsize24 white proximanova-semibold text-left my-auto">Predictive Analysis</h3>
                <p class="proximanova fontsize18 white body-copy mt-3 text-left">{{predictiveAnalysis}}</p>
                <div class="mt-4 text-left">
                    <div class="d-flex mt-3" v-for="(item, index) in analysisList" :key="index">
                        <div>
                        <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="" alt="tickmark">
                        </div>
                        <span class="fontsize16 white proximanova ml-3 mt-1">{{item}}</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="container selectedContainer">
                <div class="col-lg-12 pl-0 pr-0 d-flex flex-wrap justify-content-between articlesSection mt-5 pt-5">
                    <div class="col-lg-6 mt-4 pl-0 pr-0 pl-md-3 pr-md-3 blogcard pointer" v-for="(item, index) in technologyList" :key="index">
                        <div class="blogImg">
                            <img src="../../assets/AIinLifeInsurence1.jpg" v-if="index == 0" class="img-fluid " alt="artificial intelligence">  
                            <img src="../../assets/AIinLifeInsurence2.jpg" v-if="index == 1" class="img-fluid " alt="artificial intelligence">  
                            <img src="../../assets/AIinLifeInsurence3.jpg" v-if="index == 2" class="img-fluid " alt="artificial intelligence">  
                            <img src="../../assets/AIinLifeInsurence4.jpg" v-if="index == 3" class="img-fluid " alt="artificial intelligence">   
                        </div>
                        <div class="blogTitle p-2 pt-4 pb-4 text-left">
                            <div class="borderleftwhite mb-4">
                                <h5 class="fontsize white proximanova-semibold p-2">{{item.title}}</h5>
                            </div>
                            <span class="fontsize16 white proximanova-light" v-for="(item1, index1) in item.description" :key="index1">{{item1}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">Social Media Data</h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{socialMediaData}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h2 class="fontsize24 white proximanova-semibold text-left my-auto">Machine Learning</h2>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{machineLearning1}}</p>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{machineLearning2}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">Chatbots</h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{chatbots1}}</p>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{chatbots2}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-center">
                        <div class="borderbottomwhite width100 mb-3 mx-auto"></div>
                        <h3 class="fontsize28 white proximanova-semibold">Conclusion</h3>
                        <p class="fontsize16 white proximanova-light mt-3">{{conclusion}}</p>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Life Insurance | Top Technology Trends in Life Insurance Sector",
            meta: [
                { name: 'description', content:  'Technology Trends in Life Insurance Sector: Predictive analysis, AI & future of life insurance, Machine learning, social media data, Chat bots and many more!'},
                { name : 'keywords', content : 'Life insurance, Life insurance sector, Insurance technology, Insurance industry, Future of insurance, Top technology trends in insurance industry, Top trends in life insurance.'}
            ]
        }
    }, 
    data() {
        return {
            description1 : 'Advanced technology has already evolved into an essential part of the insurance industry. Nowadays, one can efficiently compare life insurance quotations by clicking a button. Not just this, handling coverage or checking can efficiently do the system status via mobile app, or for all year financial questions, you can guide sites such as Learn Bonds. These days, writing insurance has mostly become a thing of the past.',
            description2 : 'Insurance technology is possible to grow even more in the coming years. While multiple insurance technology trends are arising, they are becoming more popular throughout the insurance industry.',
            description3 : "Insurance companies looking for a competitive benefit can consider following one or more of these insurance technology trends.",
            predictiveAnalysis : "Many insurance companies use predictive analytics to collect data from anticipating and understanding customer behavior. However, there are unique ways that can utilize to improve accuracy. Nowadays, insurance firms can operate predictive analytics for:",
            analysisList : [
                'Determining the risk of fraud',
                'Pricing and risk preference',
                'Sorting claims',
                'Recognizing insurance buyers at risk of cancellation',
                'Predicting trends'
            ],
            technologyList : [
                {title : 'AI and the future of Life Insurance', description : ["These days, artificial intelligence seems to be everywhere. It's voice assistants like Alexa, Google Now, and Siri. You can review the weather simply by asking for the temperature out loud, and it can notify you when it's time to collect better laundry detergent before you've even added it to the grocery checklist. But different areas that exist still on top of mind, like insurance, have also started to use artificial intelligence."]},
                {title : 'AI & life insurance companies', description : ["AI has created a lot of positive modifications throughout the insurance sector. For example, by following policyholder's behavior after the wheel, auto insurance companies have encouraged more trustworthy driving and helped reduce risky behavior. Life insurance providers have customized coverage possibilities and automate the underwriting process, authorizing flexible plans to fit consumers' needs. AI could allow insurance companies better understand their customers, which could help them enhance their customer service."]},
                {title : 'AI & Customers', description : ["AI is allowing future and existing life insurance customers as well. New customers shopping for insurance can respond to just a few questions and quickly compare real-time quotations to find the right coverage for their individual needs. Some providers offer existing client programs that follow practices via AI and use the data collected to reward healthy manners and potentially alter future rates."]},
                {title : 'AI & the future', description : ["AI is constantly growing, so more additional modifications are definitely on the horizon. As tech develops, AI will take on better tasks and use more data. These advancements could influence AI's role in life insurance. Life insurance companies created coverage options around entire groups of individuals in the past. But as technology and AI has evolved, coverage has taken on a more personalized process. This is good news for the customer because it better suits their requirements when a range is designed with individuals in mind and provides greater access to protection.","Artificial Intelligence has endless applications in the future of insurance. It's an exciting new tool that has more significant possibility to provide more custom policies, delivering more great personalization and protection for you and your family."]},
            ],
            socialMediaData : "The popularity of social media and its function in the insurance industry is succeeding further in clever promotions and marketing techniques. Engaging in social media data enhances the risk assessment for the life insurer, enables fraud detection abilities, and allows an utterly new knowledge for the customers. Moreover, SMD empowers the customer to interact with the insurer, improving the insurance company's credibility. Similarly, customers can file a claim, review quotations, and request additional assistance simply by login in using their Facebook credentials.",
            machineLearning1 : "To enhance the exactness of the insurance firms, multiple insurance technology trends are interlinked with one another. According to Forbes, Machine learning is a development of Artificial Intelligence. However, it is more detailed. Machine learning is made on the idea that we can design machines for learning and processing data independently without the continuous maintenance of a human.",
            machineLearning2 : "With the benefit of machine learning, the insurance company can enhance the processing of claim settlements, but it can automate it. When files are available and digital through the cloud, they can be efficiently analyzed using pre-programmed algorithms, enhancing accuracy and processing speed. This automatic review can impact more than just claims – it can also use it for risk checks and policy administration.",
            chatbots1 : "Many companies carry out most of their customer contacts via chatbots these days. It is calculated that by 2025 more than 90% of all customer communications will be managed by chatbots.",
            chatbots2 : "Chatbots are AI-enabled that can deliver innovative communication with the customers and assist them with the majority of the generally faced problems. Chatbots preserve an insurance firm a lot of duration and resources. These bots will finish standard procedures like guideline application, filing claims, and many others. Human interactions will be reserved for complicated prerequisites and cases.",
            conclusion : "The insurance companies are consistently looking for the latest and highest products in insurance technology. It allows them to stay ahead of competitors and deliver the best experiences the customers desire in the modern market. With all the technical improvements needed in recent years, you can follow these technology trends to create your industry more powerful and effective."
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .7), rgba(31,31,31,.7)), url('../../assets/lifeinsurence.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 100px 0;
}
/* section2 related styles */
.section2{
    padding-top: 4% !important;
    padding-bottom: 2% !important;
    /* background-color: white; */
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.blogImg {
    z-index: 3;
    position: relative;
}
.blogTitle {
    position: relative;
    /* background-color: #1c2027 !important; */
    background-color: black;
    z-index: 4;
}
.blogcard:hover .blogTitle {
    margin-top: -25px;
    color: white;
    transition-duration: 0.3s;
    opacity: 1 !important;
}
.blogcard{
    transition-duration: 0.3s;
}
.blogcard:hover {
    opacity: .8;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>