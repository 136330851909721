<template>
    <div class="">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h2 class="fontsize40 white proximanova-semibold">About us</h2>
                    </div>
                    <p class="proximanova fontsize20 white body-copy text-left">{{description1}}</p>
                    <p class="proximanova fontsize20 white body-copy text-left">{{description2}}</p>
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="container">
                <div class="borderbottomwhite width100 ml-lg-3 mb-3 ml-2"></div>
                <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                    <h2 class="fontsize28 white proximanova-bold">Great Partners Make US Better </h2><br>
                    <p class="fontsize22 white proximanova-semibold">What makes us one of the leading software developments and staffing company?</p>
                </div>
                <div class="col-12 d-lg-flex">
                    <div class="col-lg-7 text-left pl-0 pr-0 mt-5">
                        <p class="fontsize20 white proximanova">{{greatPartners}}</p>
                    </div>
                    <div class="col-lg-5 my-auto">
                        <img src="../../assets/greatPartners.jpg" alt="great partners" class="img-fluid mblsecimg">
                    </div>
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="container text-center">
                <div class="borderbottomwhite width100 mx-auto"></div>
                <div class="div-block-41 pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mt-4">
                    <h5 class="fontsize28 white proximanova-bold">Our Testimonials </h5>
                    <p class="fontsize20 white proximanova">{{testimonialsDescription}}</p>
                </div>
                <div class="col-12">
                    <span class="">Note : Testimonials Content should Add Here...</span>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    data() {
        return {
            description1 : 'We are a highly ambitious and dynamic development and staffing company with our business commenced in the year. From that point in time, our goal invariably has been to exploit the power of connections of our patrons, clients, and employees. The start of our business came off with a marvellous vision, and we label it as Network Computing. It depends on one basic fundamental: the more massive the set of connections turns up, the more powerful it is.',
            description2 : 'Because of this, we further a milieu of consideration: every distinctive viewpoint and a set of experiences make the Network more powerful. We plan to outfit that strong point to work for yourself and bring you.',
            greatPartners : "Cognax tech is one of the world's advanced software developments and staffing business companies. Our excellence in the administrations and industry-leading position speaks a lot about our massive success in providing IT staffing solutions and IT services and talent management insight knowledge to ensure a truly better competitive advantage in this unstable market. Our industry-target expertise plays a seminal role in enabling us to make quick work of the involvedness of your business. No matter whether you’re staffing or software development needs are short-term or long-term, we at Cognax tech will achieve them successfully within a short and limited turnaround.",
            testimonialsDescription : 'We aim to be the extended IT partner for our clients and engage them to understand their potential. Here’s what our clients need to say about us. Cognax tech will help them achieve success within a short and limited turnaround.',
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .7), rgba(31,31,31,.7)), url('../../assets/aboutUs.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}
@media screen and (min-width:769px) {
    .card {
        width: calc(33.33333% - 1.11111rem) !important;
    }
}
</style>