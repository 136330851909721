import Vue from 'vue'
import App from './App.vue'
// import VueRouter from 'vue-router'
import routes from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueToast from 'vue-toast-notification';
import VueMeta from 'vue-meta'
import VeeValidate from "vee-validate"
const config = {
  classes: true,
  events: "blur", //Nag- This makes validations to fire on blur and on submit
  classNames: {
    invalid: "is-invalid"
  }
};
Vue.use(VueMeta)
Vue.use(VeeValidate, config);
export const EventBus = new Vue();
Vue.config.productionTip = false
Vue.use(VueToast, {
  type: 'center',
  position : 'top',
  duration: 2000,
  wordWrap: true
});
// Vue.use(VueRouter)

new Vue({
  routes,
  render: h => h(App),
}).$mount('#app')
