import { render, staticRenderFns } from "./AndroidApplicationDevelopment.vue?vue&type=template&id=15ba14a3&scoped=true&"
import script from "./AndroidApplicationDevelopment.vue?vue&type=script&lang=js&"
export * from "./AndroidApplicationDevelopment.vue?vue&type=script&lang=js&"
import style0 from "./AndroidApplicationDevelopment.vue?vue&type=style&index=0&id=15ba14a3&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ba14a3",
  null
  
)

export default component.exports