<template>
    <div class="">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h2 class="fontsize40 white proximanova-semibold">Our offering</h2>
                    </div>
                    <p class="proximanova fontsize20 white body-copy text-left">{{description}}</p>
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="container">
                <div class="borderbottomwhite width100 ml-lg-3 mb-3 ml-2"></div>
                <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                    <h2 class="fontsize22 white proximanova-bold">Business</h2><br>
                </div>
                <div class="col-12 pl-0 pr-0 d-flex flex-wrap justify-content-between">
                    <div class="card shadow pl-0 pr-0 mt-4 text-left borderadius16 backgroundColorwhite">
                        <img src="../../assets/aboutUsArtificial.jpg" class="img-fluid borderadiustop16" alt="artificial intelligence">
                        <div class="pl-4 pr-4 p-2 borderadiusbottom16 backgroundColorwhite">
                            <h3 class="fontsize20 black proximanova-bold pt-3">Artificial Intelligence</h3>
                            <p class="proximanova-light fontsize16 black body-copy text-left">{{artificialIntelligence}}</p>
                        </div>
                    </div>
                    <div class="card shadow pl-0 pr-0 mt-4 text-left borderadius16 backgroundColorwhite">
                        <img src="../../assets/aboutUsMobility.jpg" class="img-fluid borderadiustop16" alt="mobility">
                        <div class="pl-4 pr-4 p-2 borderadiusbottom16 backgroundColorwhite">
                            <h3 class="fontsize20 black proximanova-bold pt-3">Mobility</h3>
                            <p class="proximanova-light fontsize16 black body-copy text-left">{{mobility}}</p>
                        </div>
                    </div>
                    <div class="card shadow pl-0 pr-0 mt-4 text-left borderadius16 backgroundColorwhite">
                        <img src="../../assets/aboutUsSmartInfra1.jpg" class="img-fluid borderadiustop16" alt="smart infrastructure">
                        <div class="pl-4 pr-4 p-2 borderadiusbottom16 backgroundColorwhite">
                            <h3 class="fontsize20 black proximanova-bold pt-3">Smart Infrastructure</h3>
                            <p class="proximanova-light fontsize16 black body-copy text-left">{{smartInfrastructure}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    data() {
        return {
            description : 'Cognax tech is an up-and-coming software development company specializing in providing the best to its clients within their budget. Our company has carved a niche by offering quality services to our clients worldwide. With an unmatched combination of comprehensive business domain understanding and technical know-how, multiplied by profound knowledge of the innovative industry trends and technological innovations – Careertuner plans and executes quality-oriented software development and delivers custom-made solutions that are sure to fulfill your needs.',
            artificialIntelligence : "The various sub-fields of AI research are centred around particular goals and the use of specific tools. The traditional plans of AI analysis include reasoning, knowledge representation, planning, learning, natural language processing, perception, and the capability to move and manipulate objects. General insights (the ability to solve an unplanned problem) is among the field's long-term goals. AI researchers have adapted and integrated a wide range of problem-solving techniques to solve these problems—including search and mathematical optimization, formal logic, artificial neural networks, and methods based on statistics, probability and economics. AI also draws upon computer science, psychology, linguistics, philosophy, and many other fields.",
            mobility : "Delivering affordable and engaging end-user experiences through various concurrent digital channels and devices is vital for organisations seeking to communicate with customers in a fragmented networked economy. This unit aims to cultivate technical skills and methods in developing mobile-web applications that leverage content by offering flexible and personalised user experiences across multiple customer-facing touchpoints. The priority is developing and executing engaging web-based applications that combine rich content with principles in customer-centricity, user experience and accessibility relevant to dynamic mobile-device capabilities.",
            smartInfrastructure : "Smart Infrastructure connects the real and digital worlds across energy systems, buildings and industries, improving how people live and work and especially enhancing efficiency. We work together with customers and partners to create an ecosystem that intuitively responds to people's needs and helps customers achieve their business goals. It helps our customers succeed, communities progress, and supports development to protect our planet for the next generation.These infrastructures are necessary for a city to leverage technology and smart solutions, thereby enhancing the day-to-day of its citizens. This is why an increasing number of firms and institutions are implementing them and adapting. The smart city international market is expected to reach 1.56 billion dollars by 2025."
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .7), rgba(31,31,31,.7)), url('../../assets/aboutBusiness.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 200px 0;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}
@media screen and (min-width:769px) {
    .card {
        width: calc(33.33333% - 1.11111rem) !important;
    }
}
</style>