<template>
    <div>
        <Navbar class="fixed-top"/>
        <router-view class="router"/>
        <!-- <div class="card cookiescard black shadow p-4" v-if="showCookiesAlert">
            <div class="d-flex mx-auto">
                <span class="proximanova fontsize14 black my-auto">This website user cookies to ensure you get the best experience on our website</span>
                <div class="d-flex mx-auto my-auto">
                    <button class="cookieBtn icon-scroll text-nowrap pl-3 pr-3" @click="setCookies">Accept</button>
                    <button class="cookieBtn text-nowrap pl-3 pr-3" @click="declineCookies">Decline</button>
                </div>
            </div>
            <span class="closeBtn" @click="declineCookies">X</span>
        </div> -->
    </div>
</template>
<script>
import Navbar from './Navbar'
export default {
    data() {
        return{
            cookie : false,
            showCookiesAlert : false,
        }
    },
    components : {
        Navbar
    },
    created() {
    },
    mounted(){
        let cookie = localStorage.getItem('cookie')
        if(cookie !== undefined && cookie !== null) {
            this.cookie = cookie
            if(this.cookie === 'true') {
                this.showCookiesAlert = false
            }else{
                this.showCookiesAlert = true
            }
        }else{
            localStorage.setItem('cookie', false)
            this.showCookiesAlert = true;
        }
    },
    methods : {
        setCookies() {
            localStorage.setItem('cookie', true)
            this.cookie = true;
            this.showCookiesAlert = false
        },
        declineCookies() {
            this.showCookiesAlert = false
        }
    }
}
</script>
<style scoped>
.fixed-top{
    z-index: 1000 !important;
}
.cookiescard{
    border: 1px solid black;
    border-radius:  0;
    /* background: #00DC4D; */
    color: white;
    width: 100%;
    position: fixed;
    bottom: 0;
    /* margin-left: 5%; */
    /* margin-bottom: 5%; */
    z-index: 5;
}
.closeBtn{
    color: #00DC4D !important;
    width: 24px;
    height: 24px;
    background: black;
    padding: 2px;
    font-size: 14px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    margin: 10px 10px 0 0;
}
@media screen and (max-width : 768px) {
    .cookiescard{
        width: 100%;
    }
}
</style>