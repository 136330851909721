<template>
    <div class="">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">IOS Application Development </h1>
                    </div>
                    <p class="proximanova fontsize24 white body-copy text-left">Connected secure and scalable application development.</p>
                </div>
                <div class="d-flex col-12 pl-0 pr-0 flex-wrap">
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/database.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">TECHNOLOGY</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{technology}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/clients.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">CLIENTS</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Clients}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/experince.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">EXPERIENCE</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Experience}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/services.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">SERVICES</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Services }}</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-10 my-auto">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <h3 class="fontsize28 white proximanova-semibold text-left">Our IOS Services</h3><br>
                        <div class="col-lg-12 pl-0 pr-0">
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse1}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse2}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section3 aboutCompanyBG">
            <div class="container text-left">
                <div class="borderbottomwhite width100 ml-lg-3 ml-2 mb-3"></div>
                <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                    <h3 class="fontsize28 white proximanova-semibold">About IOS Application Development</h3><br>
                </div>
                <div class="pl-md-3 pr-md-3">
                    <p class="fontsize20 white proximanova-light">{{aboutService1}}</p>
                    <p class="fontsize20 white proximanova-light">{{aboutService2}}</p>
                    <p class="fontsize20 white proximanova-light">{{aboutService3}}</p>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Top IOS Application Development | iPhone Application Development",
            meta: [
                { name: 'description', content:  "Learn how to build an iOS application with identifying key developer requirements, choose right programming language and cloud-based resources to improve results."},
                { name : 'keywords', content : 'iOS application. iOS application development, iOS app dev, iOS app development, iOS application development service.'}
            ]
        }
    },
    data() {
        return {
            aboutCourse1 : 'Today, iOS clients are on an upsurge due to the demand for the latest iOS apps. However, most mobile applications cannot keep up with the quality prerequisites of app stores or end-user experience. cognax tech can help you design feature-rich iOS mobile applications to suit your business needs.',
            aboutCourse2 : 'Mobile business applications are all about getting your work done effectively and efficiently. This cognax tech has helped organizations develop mobile applications that streamline business environments. Our applications guarantee that organizations are always in touch with their employee, customers, and vendors. cognax tech’s applications provide organizations with the flexibility to perform critical tasks with ease. We help managers make informed choices by giving them access to bankable data. cognax tech’s experienced iOS app developers are equipped with in-depth technical expertise. Our rich industry experience helps us deliver robust applications for diverse business solutions. Get used to doing more at a lesser cost and time.',
            technology : 'Our iOS development team works with technologies like Swift, Objective-C, JSON, Web Services, PhoneGap, XML, CSS, JavaScript, and HTML5.',
            Clients : 'We have helped client innovate their business operations using scalable and strong iOS solutions that certify organizations with efficiency and productivity.',
            Experience : 'Our in-depth specialization and industry expertise has helped us utilize the best of tools such as XCode, SQLite, SVN, Screen Flick, etc., to deliver customized features.',
            Services : 'We have helped businesses build solutions that deliver a personalized experience that better engage their customers. Our services extend across various domains and skillsets.',
            aboutService1 : 'Cognax tech’s iOS application development approach is trusted among several enterprises. We help organizations meet tight deadlines with our hassle-free, efficient and on-time delivery. We help organizations experience secure networking with on-the-go mobility and productive collaboration.',
            aboutService2 : 'Our applications blend not just technology but a defined and researched user experience. We develop applications that focus on app interactivity and high user engagement. Cognax tech leverages the best of SDK, OS, and Objective C Technology to deliver a bug-free, up and running, scalable solution.',
            aboutService3 : 'Our app consultants and professionals are committed to helping you scale success with speed and reliability. Talk to our experts to plan your next application development to be more efficient and cost-effective.'
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .8), rgba(31,31,31,.8)), url('../../assets/ios.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 200px 0;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.aboutCompanyBG{
    background-image: linear-gradient(rgba(44, 62, 80, .8), rgba(44, 62, 80, .8)), url('../../assets/ios1.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>