import VueRouter from 'vue-router'
import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/home.vue'
import LandingPage from './components/LandingPage.vue'
import InternetOfThings from './views/services/InternetOfThings.vue'
import WebApplicationDevelopment from './views/services/WebApplicationDevelopment.vue'
import BusinessTechnologyConsulting from './views/services/BusinessTechnologyConsulting.vue'
import CloudComputingApplication from './views/services/CloudComputingApplication.vue'
import IOSApplicationDevelopment from './views/services/IOSApplicationDevelopment.vue'
import AndroidApplicationDevelopment from './views/services/AndroidApplicationDevelopment.vue'
import HybridApplicationDevelopment from './views/services/HybridApplicationDevelopment.vue'
import Recruiting from './views/services/Recruiting.vue'
import AboutUs from './views/aboutus/AboutUs.vue'
import WhoWeAre from './views/aboutus/WhoWeAre.vue'
import ArtificialIntelligence from './views/articles/ArtificialIntelligence.vue'
import EdgeAI from './views/articles/EdgeAI.vue'
import DigitalizationInMobility from './views/articles/DigitalizationInMobility.vue'
import AIinAutomobileIndustry from './views/articles/AIinAutomobileIndustry.vue'
import ELearning from './views/articles/ELearning.vue'
import LifeSciencesIndustry from './views/articles/LifeSciencesIndustry.vue'
import TopTechnologyTrendsInLifeInsurence from './views/articles/TopTechnologyTrendsInLifeInsurence.vue'
import ContactUs from './views/ContactUs.vue'
import NotFound from './components/NotFound.vue'
Vue.use(Router)
let router  = new VueRouter({
    mode: 'history',
    routes: [
        { 
            path: '/', 
            name:'', 
            component : Home,
            children : [
                { path: '/', name : 'home', component : LandingPage , meta: {requiresAuth: true}},
                { path: '/services/internet-of-things/', name : 'iot', component : InternetOfThings , meta: {requiresAuth: true}},
                { path: '/services/web-application/', name : 'webApp', component : WebApplicationDevelopment , meta: {requiresAuth: true}},
                { path: '/services/it-consulting/', name : 'businessTechnology', component : BusinessTechnologyConsulting , meta: {requiresAuth: true}},
                { path: '/services/cloud-computing/', name : 'cloudComputing', component : CloudComputingApplication , meta: {requiresAuth: true}},
                { path: '/services/ios-development/', name : 'iosApplication', component : IOSApplicationDevelopment , meta: {requiresAuth: true}},
                { path: '/services/android/', name : 'androidApplication', component : AndroidApplicationDevelopment , meta: {requiresAuth: true}},
                { path: '/services/hybrid-application-development/', name : 'hybridApplication', component : HybridApplicationDevelopment , meta: {requiresAuth: true}},
                { path: '/services/recruiting/', name : 'recruiting', component : Recruiting , meta: {requiresAuth: true}},
                { path: '/about/business', name : 'aboutUsBusiness', component : AboutUs , meta: {requiresAuth: true}},
                { path: '/about-us', name : 'aboutUs', component : WhoWeAre , meta: {requiresAuth: true}},
                { path: '/artciles/artificial-intelligence', name : 'artificialIntelligence', component : ArtificialIntelligence , meta: {requiresAuth: true}},
                { path: '/artciles/edge-ai', name : 'edgeAI', component : EdgeAI , meta: {requiresAuth: true}},
                { path: '/artciles/digitalization-in-mobility', name : 'digitalizationInMobility', component : DigitalizationInMobility , meta: {requiresAuth: true}},
                { path: '/artciles/ai-is-transforming-automobile-industry', name : 'aiInAutomobileIndustry', component : AIinAutomobileIndustry , meta: {requiresAuth: true}},
                { path: '/artciles/elearning', name : 'elearning', component : ELearning , meta: {requiresAuth: true}},
                { path: '/artciles/life-sciences-industry', name : 'lifeSciences', component : LifeSciencesIndustry , meta: {requiresAuth: true}},
                { path: '/artciles/top-technology-trends-in-life-insurance', name : 'toptechnology', component : TopTechnologyTrendsInLifeInsurence , meta: {requiresAuth: true}},
                { path: '/contact-us/', name : 'contactUs', component : ContactUs , meta: {requiresAuth: true}},
            ]
        },
        { path: '*', component: NotFound , meta: {requiresAuth: true} }
    ]
})
//nav guards
router.beforeEach(async (to, from, next) => {
    const { requiresAuth } = to.meta
    if (requiresAuth) {
        next();
    }else{
        next('/')
        //window.alert("You don't have a y permissions to access this page")
    }
})
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}
export default router;
