import { render, staticRenderFns } from "./BusinessTechnologyConsulting.vue?vue&type=template&id=6caf2854&scoped=true&"
import script from "./BusinessTechnologyConsulting.vue?vue&type=script&lang=js&"
export * from "./BusinessTechnologyConsulting.vue?vue&type=script&lang=js&"
import style0 from "./BusinessTechnologyConsulting.vue?vue&type=style&index=0&id=6caf2854&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6caf2854",
  null
  
)

export default component.exports