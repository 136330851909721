<template>
  <div id="app">    
    <router-view class="router"/>    
  </div>
</template>

<script>

import router from  './router'
export default {
  router,
  name: 'App', 
  metaInfo() {
    return { 
      title: "Software Development and Recruiting Services | Cognaxtech",
      meta: [
        { name: 'description', content:  'Cognaxtech, Software Development and recruiting company in the industry with its clients around the globe. Which offers quality service and technology innovations.'},
        { name : 'keywords', content : 'Software Development, Recruiting, IoT, Internet of Things, Android Application, Cloud Computing, Web Applications, IT Consulting, IOS Development, Hybrid Application, Artificial Intelligence, AI, Digital mobility, Life Sciences, Life Insurance.'},
      ],
      // link: [
      //   {rel: 'canonical', href: ''}
      // ]
    }
  }, 
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* background-color: rgba(240, 242, 245, .4); */
  /* background-color: #7d8a97; */
  /* background-color: #1c2027; */
  background-color: black;
  /* background: white; */
  /* margin-top: 60px; */
}


</style>
