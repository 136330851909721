<template>
    <div class="edgeAi">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">AI is transforming the automobile industry</h1>
                    </div>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description1}}</p>
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="container selectedContainer">
                <div class="col-lg-12 d-flex flex-wrap justify-content-between articlesSection mt-5 pt-5">
                    <div class="col-md-6 col-lg-4 mt-4 pl-0 pr-0 pl-md-3 pr-md-3 blogcard pointer" v-for="(item, index) in AIautomobiles" :key="index">
                        <div class="blogImg">
                            <img src="../../assets/AIinAutomobilesCard1.jpg" v-if="index == 0" class="img-fluid " alt="artificial intelligence">   
                            <img src="../../assets/AIinAutomobilesCard2.png" v-if="index == 1" class="img-fluid " alt="artificial intelligence">
                            <img src="../../assets/AIinAutomobilesCard4.jpg" v-if="index == 2" class="img-fluid " alt="artificial intelligence">
                            <img src="../../assets/AIinAutomobilesCard3.jpg" v-if="index == 3" class="img-fluid " alt="artificial intelligence">
                            <img src="../../assets/AIinAutomobilesCard5.jpg" v-if="index == 4" class="img-fluid " alt="artificial intelligence">
                            <img src="../../assets/AIinAutomobilesCard6.jpg" v-if="index == 5" class="img-fluid " alt="artificial intelligence">
                        </div>
                        <div class="blogTitle p-2 pt-4 pb-4 text-left">
                            <div class="borderleftwhite mb-4">
                                <h3 class="fontsize24 white proximanova-semibold p-2">{{item.title}}</h3>
                            </div>
                            <span class="fontsize16 white proximanova-light" v-for="(item1, index1) in item.description" :key="index1">{{item1}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-7-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">Self-driving Cars </h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{selfdriving1}}</p>
                        <img src="../../assets/selfdriving.jpg" alt="manufacturing industries" class="img-fluid mt-2 mb-2 d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{selfdriving2}}</p>
                    </div>
                    <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                        <img src="../../assets/selfdriving.jpg" alt="manufacturing industries" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">The Rest  </h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{rest1}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-center">
                        <div class="borderbottomwhite width100 mb-3 mx-auto"></div>
                        <h3 class="fontsize28 white proximanova-semibold">Conclusion</h3>
                        <p class="fontsize16 white proximanova-light mt-3">{{conclusion}}</p>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Artificial Intelligence is Transforming the Automobile Industry",
            meta: [
                { name: 'description', content:  'AI in Automobile industry: Research and Development, Customer experience, Manufacturing and Operations, Supply chain, Mobility services, Self-driving cars and Many! '},
                { name : 'keywords', content : 'Artificial Intelligence, AI, Artificial Intelligence in Automobile Industry, Artificial Intelligence transforming automobile, AI reshaping automobile industry, AI innovations in automobile.'}
            ]
        }
    }, 
    data() {
        return {
            description1 : 'Artificial intelligence is altering every factor of the automobile industry. In today’s world, it’s impossible to imagine our lives without public transportation and private vehicles. The automotive industry is no exception to discoveries and trends. Industry giants such as Kia, Nissan, Mercedes, and Tesla are reshaping the geography through machine learning in the automotive industry.',
            AIautomobiles : [
                {title : 'Research and Development ', description : ['This is one of the most important applications of artificial intelligence in automobiles. Current R&D is concentrated on gaining more significant speed and economical design. For instance, the General Motors prototype searches this feasibility through machine learning. They are operating on a project named “Dreamcatcher system.”', 'Likewise, Continental is working on a virtual simulation program to develop vehicle test data via Advanced Driver-Assistance System (ADAS).']},
                {title : 'Customer/driver Experience', description : ['Enforcing artificial intelligence in the automotive industry also implicates driver and customer experience. Major automotive companies are functioning on connected car experience. For example, Toyota presents both hybrid navigation and a voice assistant.', 'The voice assistant design consists of access instructions, an audio system, and the navigation system. On the other hand, hybrid navigation consists of an onboard navigation system and cloud-based map data.']},
                {title : 'Supply chain', description : ['The use of AI in the automobile industry has developed supply chain operations, especially quality control. For instance, Audi is investigating the application of AI in the automotive sector, i.e., quality control, by utilizing image recognition software and smart cameras.', 'The system generates millions of images and recognizes the finest of faults or cracks. It can determine the accuracy of AI in quality control because it notices flaws at the pixel level.']},
                {title : 'Manufacturing and Operations', description : ['Automotive firms have long utilized car welding, painting, assembling, sorting, moving, and collecting items. The AI automotive industry has significantly reduced human work in manufacturing. ', 'Artificial intelligence in automobile manufacturing has become essential to detect component failure and subsequent downtime. General Motors, for instance, assembled an image category mechanism to control component failure in nearly 7,000 robots.']},
                {title : 'Mobility Services', description : ['Companies like Lyft and Uber use predictive analytics to adjust prices in real-time according to different geographical locations. AI in automobiles has been shown to improve fleet management and last-mile delivery.', 'Michelin is creating improvements in this area by developing a tire-monitoring system to keep real-time tabs on the wearing and performance of tires. This synergy of AI and motorcars has consolidated package load time by 15% for Mercedes Benz!']},
                {title : 'Marketing/sales', description : ["Like every other industry, the automobile industry has become highly competitive in recent years. Hence, personalization is the key to winning customers' hearts. The role of AI in the car industry has become a crucial tool in gaining that competitive edge.", 'A good example of that is Volkswagen. The company is operating machine learning for development forecasting and sales planning.']},
            ],
            selfdriving1 : "One of the well-known applications of AI in the automobile sector is self-driving car technology. Organizations such as Google Waymo, Tesla, and NVIDIA support huge services in this technology. ",
            selfdriving2 : "Earning customer trust regarding safety concerns and making fully independent vehicles legal stay is the most critical challenge of artificial intelligence and cars. The use of computer vision for recognizing pedestrians, streetlights, and objects is the main component of autonomous vehicles.",
            rest1 : "While the impact of AI is not only restricted to automobile manufacturing or its associated technology assistance providers. AI is also being leveraged to make consumers' car buying and selling journeys seamless. Fantasize a lot where AI-enabled customer interface techniques tell you which motorcar is the best for you based on your driving, penalties received, insurance claimed, and health data. AI can finely adjust and customize product offerings and measures down to the minutest detail.",
            conclusion : "Forthcoming AI-based technologies will add more practical value to cars, allowing accelerated show, maximizing production capacity, and gathering data with a memorable user experience. The future financial growth fuel for the automotive sector will be big data. Utilizing BIG DATA, an AI/ML-based method can accurately predict the supply-demand volatility and provide a customer reaches the right product based on their profile. Artificial intelligence is truly and properly here. It is transforming the automobile sector from driving to engaging with the automobile. And the good news is, as an industry, not only are we assuming this technology with open arms, we are ready to use it as well."
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .6), rgba(31,31,31,.6)), url('../../assets/aiautomobile.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 100px 0;
}
/* section2 related styles */
.section2{
    padding-top: 4% !important;
    padding-bottom: 4% !important;
    /* background-color: white; */
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.blogImg {
    z-index: 3;
    position: relative;
}
.blogTitle {
    position: relative;
    /* background-color: #1c2027 !important; */
    background-color: black;
    z-index: 4;
}
.blogcard:hover .blogTitle {
    margin-top: -25px;
    color: white;
    transition-duration: 0.3s;
    opacity: 1 !important;
}
.blogcard{
    transition-duration: 0.3s;
}
.blogcard:hover {
    opacity: .8;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}
@media screen and (min-width : 1000px) {
    .selectedContainer{
        max-width: 1250px
    }
}
</style>