<template>
    <div class="">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">Hybrid Application Development </h1>
                    </div>
                    <p class="proximanova fontsize24 white body-copy text-left">Developing high-performance scalable and secure applications.</p>
                </div>
                <div class="d-flex col-12 pl-0 pr-0 flex-wrap">
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/database.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">TECHNOLOGY</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{technology}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/clients.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">CLIENTS</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Clients}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/experince.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">EXPERIENCE</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Experience}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/services.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">SERVICES</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Services }}</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <h3 class="fontsize28 white proximanova-semibold text-left">Our Hybrid Application Services</h3><br>
                        <div class="col-lg-12 pl-0 pr-0">
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse1}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse2}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse3}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse4}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse5}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Hybrid Application Development | What is Hybrid App Development",
            meta: [
                { name: 'description', content:  "Hybrid app combine elements of both native and web apps. Where the core of the application is written using web technologies. Learn more about the key requirements."},
                { name : 'keywords', content : 'Hybrid application development, Hybrid app development, Hybrid mobile apps, what is hybrid application development, Hybrid mobile application development.'}
            ]
        }
    },
    data() {
        return {
            aboutCourse1 : 'Hybrid mobile application development lets you leverage the most useful of both native and mobile web technologies. Cognax tech can help you reduce cost and save time to build robust applications that can help your business.',
            aboutCourse2 : 'Our development expertise combines robust HTML5 development and advanced mobile frameworks such as PhoneGap.',
            aboutCourse3 : 'With numerous devices, platforms, and technologies, the growth of the mobile industry is phenomenal. Today, organizations are looking at opportunities to limit overhead costs and decision uncertainties with solutions that can be used across multiple platforms. Here, hybrid mobile application development enables you to produce an iOS and Android mobile application utilizing the same code. With the codebase written in HTML5 CSS3 and JavaScript, a hybrid mobile application lets you reuse the code across multiple mobile operating systems.',
            aboutCourse4 : "Hybrid application has its drawbacks, with lower performance for numerous layers and limited UX experience. Cognax tech mobile development team focuses on a rapid deployment approach so that you can get the best of both worlds. Our team's in-depth expertise in hybrid technologies can assist organizations in lowering the cost of development while leveraging modern JavaScript with a smooth UX. We offer quicker time to market, ensuring that you don't miss out on interesting and timely opportunities. Get the best native features for your application and enrich your customer experience. Cognax tech approach makes it easier to maintain and deploy upgrades, essentially boosting productivity all around.",
            aboutCourse5 : 'Cognax tech mobile team has helped numerous enterprises to choose their mobile deployment process by analyzing their need across multiple parameters. Talk to us, and we can manage, scale, and monitor your app 24 x 7 to ensure 100% uptime.',
            technology : 'Our mobile application team can assist you in getting the most out of HTML5 and advanced frameworks such as Sencha and PhoneGap to develop scalable and strong mobile applications.',
            Clients : 'We have helped enterprises leverage their mobile applications across numerous platforms, empowering them with tools to improve efficiency and profitability.',
            Experience : 'We have over 10+ years of experience developing and deploying innovative applications that help you reach a much larger customer base with improved user experience.',
            Services : 'We have helped businesses build solutions that deliver a personalized experience that better engages their customers. Our services expand across different domains and skillsets.',
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .85), rgba(31,31,31,.85)), url('../../assets/hybrid.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 200px 0;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>