<template>
    <div class="edgeAi">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">Edge AI</h1>
                    </div>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description1}}</p>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description2}}</p>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <div class="col-lg-12 d-md-flex pl-0 pr-0 justify-content-between">
                            <div class="col-lg-6 pl-0 pr-0 my-auto">
                                <h2 class="fontsize28 white proximanova-semibold text-left">What is Edge AI?</h2><br>
                                <p class="proximanova fontsize18 white body-copy text-left">{{WhatIsEdgeAI1}}</p>
                                <p class="proximanova fontsize18 white body-copy text-left">{{WhatIsEdgeAI2}}</p>
                            </div>
                            <div class="col-lg-4 pl-0 pr-0">
                                <img src="../../assets/edgeAI.png" class="img-fluid mblsecimg" alt="Edge AI">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-center">
                        <div class="borderbottomwhite width100 mb-3 mx-auto"></div>
                        <h2 class="fontsize28 white proximanova-semibold">Edge AI is driven by Big Data and IoT</h2><br>
                        <div class="col-lg-12 d-md-flex pl-0 pr-0 justify-content-between mt-4">
                            <div class="col-lg-6 pl-0 pr-0 my-auto">
                                <img src="../../assets/edgeAI1.png" class="img-fluid mblsecimg" alt="Edge AI">
                            </div>
                            <div class="col-lg-6 my-auto">
                                <p class="proximanova fontsize18 white body-copy text-left">{{edgeAIDrivenIOT}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <div class="col-lg-12 d-md-flex pl-0 pr-0 justify-content-between">
                            <div class="col-lg-7 pl-0 pr-0 my-auto">
                                <h3 class="fontsize28 white proximanova-semibold text-left">What is Edge Computing?</h3><br>
                                <p class="proximanova fontsize18 white body-copy text-left">{{WhatIsEdgeComputing1}}</p>
                                <p class="proximanova fontsize18 white body-copy text-left">{{WhatIsEdgeComputing2}}</p>
                            </div>
                            <div class="col-lg-5 pl-0 pr-0 my-auto">
                                <img src="../../assets/edgeComputing.jpg" class="img-fluid mblsecimg" alt="Edge AI">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <div class="col-lg-12 d-md-flex pl-0 pr-0 justify-content-between">
                            <div class="col-lg-12 pl-0 pr-0 my-auto">
                                <h4 class="fontsize28 white proximanova-semibold text-left">Edge AI to run Machine Learning on Edge Devices</h4><br>
                                <p class="proximanova fontsize18 white body-copy text-left">{{edgeAIMachineLearning1}}</p>
                                <p class="proximanova fontsize18 white body-copy text-left">{{edgeAIMachineLearning2}}</p>
                                <p class="proximanova fontsize18 white body-copy text-left">{{edgeAIMachineLearning3}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-left">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <h3 class="fontsize28 white proximanova-semibold text-left">Advantages of Edge AI</h3>
                        <p class="fontsize16 white proximanova-light mt-3">{{advantageDescription1}}</p>
                        <p class="fontsize16 white proximanova-light mt-3">{{advantageDescription2}}</p>
                        <div class="col-lg-12 d-flex pl-0 pr-0 justify-content-between">
                            <div class="d-flex col-12 pl-0 pr-0 flex-wrap">
                                <div class="col-lg-6 col-md-6 mt-4 text-left d-flex flex-column">
                                    <img src="../../assets/streamline.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                                    <h5 class="fontsize20 white proximanova-semibold mt-2">Lower Data Transfer Volume</h5>
                                    <span class="fontsize16 white proximanova-light mt-3">{{lowerData}}</span>
                                </div>
                                <div class="col-lg-6 col-md-6 mt-4 text-left d-flex flex-column">
                                    <img src="../../assets/streamline.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                                    <h5 class="fontsize20 white proximanova-semibold mt-2">Cost Advantages</h5>
                                    <span class="fontsize16 white proximanova-light mt-3">{{cost}}</span>
                                </div>
                                <div class="col-lg-6 col-md-6 mt-4 text-left d-flex flex-column">
                                    <img src="../../assets/streamline.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                                    <h5 class="fontsize20 white proximanova-semibold mt-2">Speed for real-time computing</h5>
                                    <span class="fontsize16 white proximanova-light mt-3">{{speed}}</span>
                                </div>
                                <div class="col-lg-6 col-md-6 mt-4 text-left d-flex flex-column">
                                    <img src="../../assets/streamline.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                                    <h5 class="fontsize20 white proximanova-semibold mt-2">Privacy and Security</h5>
                                    <span class="fontsize16 white proximanova-light mt-3">{{privacy}}</span>
                                </div>
                                <div class="col-lg-6 col-md-6 mt-4 text-left d-flex flex-column">
                                    <img src="../../assets/streamline.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                                    <h5 class="fontsize20 white proximanova-semibold mt-2">High Availability</h5>
                                    <span class="fontsize16 white proximanova-light mt-3">{{availability}}</span>
                                </div>
                            </div>
                        </div>
                        <p class="fontsize16 white proximanova-light mt-5 pt-4">{{advantage6}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-left">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <h4 class="fontsize28 white proximanova-semibold text-left">Edge AI Software Platforms</h4>
                        <div class="col-lg-12 d-md-flex pl-0 pr-0 justify-content-between">
                            <div class="col-lg-6 pl-0 pr-0 my-lg-auto">
                                <p class="fontsize16 white proximanova-light mt-3">{{swp1}}</p>
                                <p class="fontsize16 white proximanova-light mt-3">{{swp2}}</p>
                                <p class="fontsize16 white proximanova-light mt-3 d-none d-lg-block">{{swp3}}</p>
                            </div>
                            <div class="col-lg-5  my-auto">
                                <img src="../../assets/edgeAIplatforms.jpg" class="img-fluid mblsecimg" alt="Edge AI">
                            </div>
                        </div>
                        <p class="fontsize16 white proximanova-light mt-3 d-block d-lg-none">{{swp3}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-center">
                        <div class="borderbottomwhite width100 mb-3 mx-auto"></div>
                        <h5 class="fontsize28 white proximanova-semibold">Conclusion</h5>
                        <p class="fontsize16 white proximanova-light mt-3">{{conclusion}}</p>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Edge AI | Learner Guide to Edge AI (Artificial Intelligence) ",
            meta: [
                { name: 'description', content:  'The Ultimate cheat sheet on Edge AI: What is Edge AI, Edge AI driven by Big data and IoT, What is Edge computing, Advantages of Edge AI and Edge AI software platforms.'},
                { name : 'keywords', content : 'Edge AI, Artificial intelligence, AI, what is Edge AI, Top Edge AI trends, Edge artificial intelligence, Edge AI platform.'}
            ]
        }
    }, 
    data() {
        return {
            description1 : 'With the volatile growth of mobile computing and Internet of Things (IoT) applications, billions of mobile and IoT devices are connected to the Internet, developing massive amounts of data at the network edge. Accordingly, collecting huge volumes of data in cloud data centres incurs extremely high latency and network bandwidth usage.',
            description2 : 'Hence, there is an acute need to push the boundaries of artificial intelligence (AI) to the network edge to completely release the potential of big data. Edge AI is the variety of edge computing and AI.',
            WhatIsEdgeAI1 : 'Edge AI combines Edge Computing and Artificial Intelligence to run machine learning tasks directly on connected edge devices.',
            WhatIsEdgeAI2 : 'To understand what edge ai is, we need to look at the technological trends that drive the need for moving AI computing to the edge.',
            edgeAIDrivenIOT : 'Today, in the Internet of Things (IoT) era, an unprecedented volume of data generated by connected devices needs to be collected and analyzed. This leads to the generation of large quantities of data in real-time, which requires AI systems to make the intent of data.',
            WhatIsEdgeComputing1 : "Edge computing consists of multiple strategies that bring data collection, research, and processing to the network's edge. This implies that the computing power and data storage are located where the actual data collection occurs. What is meant by the network edge? Well, depends on the use case - it could be a mobile phone, IoT device, self-driving car or even a cell tower.",
            WhatIsEdgeComputing2 : "Hence, edge computing is used to extend the cloud as it is typically implemented in edge-cloud systems, where decentralized edge nodes send processed data to the cloud.",
            edgeAIMachineLearning1 : "Edge AI combines edge computing and AI; it handles AI algorithms processing data locally on hardware, so-called edge devices. Therefore, Edge AI delivers a form of on-device AI to take advantage of quick response times with low latency, high privacy, more robustness, and better efficient network bandwidth usage.",
            edgeAIMachineLearning2 : "Edge AI is driven by arising technologies such as machine learning, neural network acceleration, and decrease. ML edge computing opens up new, robust, and scalable AI systems across multiple industries.",
            edgeAIMachineLearning3 : "The entire field is extremely new and continuously growing. Edge AI is expected to drive the AI future by progressing AI capabilities nearer to the physical world.",
            advantageDescription1 : "Edge computing allows bringing AI processing tasks from the cloud to about the end devices to overcome the inherent problems of the traditional cloud, such as high latency and the lack of security.",
            advantageDescription2 : "Therefore, moving AI estimations to the network edge opens up new opportunities for AI applications with new products and services.",
            lowerData : "The edge device processes data and only a significantly lower amount of processed data is sent to the cloud. By reducing the traffic amount across the connection between a small cell and the core network, the connection's bandwidth can be increased to prevent bottlenecks, and the traffic amount in the core network is reduced.",
            speed : "Real-time processing is an entire advantage of Edge Computing. The physical immediacy of edge devices to the data sources makes it possible to achieve lower latency, enhancing real-time data processing performance. It supports delay-sensitive applications and services such as remote surgery, tactile internet, crewless vehicles, and vehicle accident prevention. A various range of services, including decision support, decision-making, and data analysis, can be continuously provided by edge servers.",
            privacy : "Since sharing sensitive user data over networks makes it helpless to theft and misshaping, running AI at the edge allows keeping the data private. Edge computing makes it feasible to ensure that personal data never leaves the local device. For the circumstances where data must be processed remotely, edge devices can discard personally identifiable information before data transfer, thus enhancing user privacy and security.",
            availability : "Decentralization and offline credentials make Edge AI more powerful by providing quick services during network failure or cyber-attacks. Therefore, deploying AI tasks to the edge provides higher availability and overall robustness for mission-critical or production-grade AI applications.",
            cost : "Moving AI processing to the edge is highly cost-efficient because only processed, highly valuable data is sent to the cloud. While sending and storing huge amounts of information is still very expensive, small devices at the edge have become more computationally powerful – following Moore’s Law.",
            advantage6 : "In general, edge-based ML allows real-time data processing and decision-making without the realistic limits of cloud-based computing. With the growing volume of data privacy and regulatory changes such as GDPR, Edge ML might soon be the only feasible way for companies to use AI in products and services.",
            swp1 : "With machine learning driving from the cloud to the edge, the elaborateness increases. While the cloud can depend on APIs, edge AI needs IoT to handle physical edge devices that link to the cloud.",
            swp2 : "Hence, Edge AI software usually implicates a cloud-side to create the edge-side of numerous edge clients that link to the cloud. The edge-to-cloud connection is important to manage a high number of endpoints and roll out updates and security patches.",
            swp3 : "The edge architecture requires offline capabilities, remote deployment and updating, edge machine monitoring, and secure data at rest and in transit. On-device machine learning requires AI hardware and optimized edge computing chips to efficiently handle sensors or cameras data.",
            conclusion : "Edge computing is required for real-world AI applications because the standard cloud computing model is not appropriate for AI applications that are computationally intensive and need massive amounts of data."
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .7), rgba(31,31,31,.7)), url('../../assets/artificialIntelligenceEdgeAI.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 100px 0;
}
/* section2 related styles */
.section2{
    padding-top: 6% !important;
    padding-bottom: 6% !important;
    /* background-color: white; */
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>