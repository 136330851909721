import axios from "axios";

const callaxios = axios.create({
	baseURL: "http://3.228.238.128:8008",
	headers:{
        "content-type" : "application/json",
        'Access-Control-Allow-Origin' : "http://3.228.238.128:8008",
        'Access-Control-Allow-Methods' :  "POST, GET, OPTIONS, PUT, DELETE",
        'Access-Control-Allow-Headers' :  "Content-Type, X-Auth-Token, Origin, Authorization, UserId"
    }
});



export default callaxios