<template>
    <div class="edgeAi">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">Digitalization in Mobility</h1>
                    </div>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description1}}</p>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description2}}</p>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description3}}</p>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-7 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h2 class="fontsize24 white proximanova-semibold text-left my-auto">Intelligent mobility solutions </h2>
                        </div>
                        <p class="fontsize16 white proximanova-white mt-3">{{intelligentmobility1}}</p>
                        <p class="fontsize16 white proximanova-white mt-3">{{intelligentmobility2}}</p>
                        <img src="../../assets/mobilitySolutions.jpg" alt="manufacturing industries" class="img-fluid d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                        <p class="fontsize16 white proximanova-white mt-3">{{intelligentmobility3}}</p>
                        <p class="fontsize16 white proximanova-white mt-3">{{intelligentmobility4}}</p>
                    </div>
                    <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                        <img src="../../assets/mobilitySolutions.jpg" alt="manufacturing industries" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container text-left">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-7 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h3 class="fontsize24 white proximanova-semibold text-left my-auto">Intelligent Mobility Infrastructure </h3>
                        </div>
                        <p class="fontsize16 white proximanova-white mt-3">{{infrastructure1}}</p>
                        <p class="fontsize16 white proximanova-white mt-3">{{infrastructure2}}</p>
                        <img src="../../assets/mobilityInfrastructure.jpg" alt="manufacturing industries" class="img-fluid d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                        <p class="fontsize16 white proximanova-white mt-3">{{infrastructure3}}</p>
                        <p class="fontsize16 white proximanova-white mt-3">{{infrastructure4}}</p>
                    </div>
                    <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                        <img src="../../assets/mobilityInfrastructure.jpg" alt="manufacturing industries" class="img-fluid">
                    </div>
                </div>
                <p class="fontsize16 white proximanova-white mt-3 pl-3 pr-3">{{infrastructure5}}</p>
                <p class="fontsize16 white proximanova-white mt-3 pl-3 pr-3">{{infrastructure6}}</p>
            </div>
        </section>
        <section class="section2 ">
            <div class="container text-left">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-7 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h3 class="fontsize24 white proximanova-semibold text-left my-auto">Enhanced Mobility Experience</h3>
                        </div>
                        <p class="fontsize16 white proximanova-white mt-3">{{experience1}}</p>
                        <p class="fontsize16 white proximanova-white mt-3">{{experience2}}</p>
                        <img src="../../assets/mobilityExperience.jpeg" alt="manufacturing industries" class="img-fluid d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                        <p class="fontsize16 white proximanova-white mt-3">{{experience3}}</p>
                    </div>
                    <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                        <img src="../../assets/mobilityExperience.jpeg" alt="manufacturing industries" class="img-fluid">
                    </div>
                </div>
                <p class="fontsize16 white proximanova-white mt-3 pl-3 pr-3">{{experience4}}</p>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-center">
                        <div class="borderbottomwhite width100 mb-3 mx-auto"></div>
                        <h5 class="fontsize28 white proximanova-semibold">Conclusion</h5>
                        <p class="fontsize16 white proximanova-light mt-3">{{conclusion}}</p>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Digitalization in Mobility | Future of Digitalisation in Mobility",
            meta: [
                { name: 'description', content:  'Learn about Introduction to Digitalization in Mobility, Intelligent mobility solutions, Intelligent mobility infrastructure, Enhanced mobility experience and many more!'},
                { name : 'keywords', content : 'Digitalization in mobility, Mobility, Digital mobility, Digitalization of mobility, Future of mobility, Digitalization, Mobility, Digitalization improving mobility.'}
            ]
        }
    }, 
    data() {
        return {
            description1 : 'We are becoming more connected with the technologies surrounding us since digitalization is being used to address the various problems of current megatrends, specifically urbanization, globalization, the ageing of society, and environmental sustainability.',
            description2 : 'Improvement in digital technologies has transformed almost every aspect of society, mainly social media, mobile technologies and analytics. Likewise, digitalizing mobility allows people to access city resources, shaping our daily cities and communities experiences.',
            description3 : "The mobility of the future will be primarily digitally networked. This trend is irreversible. There is an extremely high potential in all transportation methods to relieve consumers, the environment, infrastructure, and resources by depending on digital solutions.",
            intelligentmobility1 : "Among trendy customer megatrends such as ownerless, simplicity, digital, and eco-lifestyles enable essential changes in the mobility sector. This allows a new capacity of mobility solutions to complete mobility needs.",
            intelligentmobility2 : "The three mobility solutions that integrate digital technologies are car-sharing, bicycle sharing and intermodal transport. Advancements in digital technologies allow for more significant processing power in smaller mobile devices by integrating GPS and Internet technologies.",
            intelligentmobility3 : "On the personal mobility side, appreciating new apps and platforms with your smartphone, you can always receive complete information for planning your journey, including details on traffic flows in real-time.",
            intelligentmobility4 : "Thanks to improved and faster information about the traffic situation or weather conditions on the logistics side, enterprises are already permanently optimising their capacity, route planning, costs, and emissions.",
            infrastructure1 : "Smart mobility infrastructure means executing wireless access technology in mobile environments to promote knowledge sharing between moving vehicles and the road or traffic systems. Old infrastructures policies must be elevated, encountered with many challenges of an ever more digital world. This includes incorporating digital technologies to create new urban mobility ideas and solutions.",
            infrastructure2 : "We mention three forms of intelligent mobility infrastructure: vehicle-to-infrastructure communication, intelligent traffic systems and vehicle-to-grid.",
            infrastructure3 : "Digitalization enables safety-related, real-time, local and situation-based services aiming to improve road safety by providing timely information to drivers.",
            infrastructure4 : "Shortly, we will see boosted behavioural changes not only in terms of mobility but also in terms of energy consumption. Electric mobility will grow as a choice to traditional explosion engines. Consequently, the domains of energy and mobility are becoming more intertwined.",
            infrastructure5 : "Digital technologies play an essential role by providing electric vehicle drivers with data about trips, driving patterns, battery conditions, and public charging stations. With this wide array of information, it is possible to reduce both the charging and general energy costs to a certain extent.",
            infrastructure6 : "Parking digitalization is also essential for a tolerable urban mobility system. Parking management systems are now being integrated to effortlessly manage supply and demand without damaging effects, enhance air quality, and reduce carbon emissions while maintaining equal access to city operations. New apps, detectors, and algorithms strengthen routing to empty parking spaces and payments. Leasing and renting out parking spaces is now easier than ever.",
            experience1 : "For an enhanced mobility experience, more comprehensive, reliable data from vehicles and infrastructures need to be. Digitalization provides users with a seamless, safer and more flexible mobility experience.",
            experience2 : "We can retain three concepts representing how digital technologies enhance the mobility experience: intra-vehicle communication, vehicle-to-vehicle communication, and autonomous vehicles.",
            experience3 : "Intra-vehicle and vehicle-to-vehicle communication consist of intelligent systems for detecting both the driver’s behaviour (fatigue and drowsiness) and the vehicle’s performance, which are critical for driver and public safety. Such systems are being developed at an ever-faster rate as driver behaviour detection systems are steadily improving. These intelligent systems also include alarms that interact with other vehicles through wireless technologies to help drivers provide relevant traffic warnings and reports.",
            experience4 : "Autonomous vehicles can plan directions and run them without human intervention. These vehicles hold the possibility of expanding road network capacity, increasing safety, and reducing driver workload. This development will also give an entirely new definition to the concept of mobility because holding or driving a car might not be as important as getting from point A to point B. Much more analysis is needed to comprehend the consequence of such mobility changes, especially for the automotive industry that might be facing disturbance via the digitalization of its products.",
            conclusion : "The project aims to present the existing legal framework for passenger transport, critically question it, take into account socio-economic aspects and the potential of digitization, and develop recommendations for action on how the legal framework can be adapted."
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .7), rgba(31,31,31,.7)), url('../../assets/aboutUsMobility.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 100px 0;
}
/* section2 related styles */
.section2{
    padding-top: 4% !important;
    padding-bottom: 4% !important;
    /* background-color: white; */
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>