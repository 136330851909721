<template>
    <div class="">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">Cloud Computing Application</h1>
                    </div>
                    <p class="proximanova fontsize24 white body-copy text-left">Developing high-performance scalable and secure applications.</p>
                </div>
                <div class="d-flex col-12 pl-0 pr-0 flex-wrap">
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/database.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">TECHNOLOGY</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{technology}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/clients.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">CLIENTS</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Clients}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/experince.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">EXPERIENCE</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Experience}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/services.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">SERVICES</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Services }}</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-10 my-auto">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <h2 class="fontsize28 white proximanova-semibold text-left">Our Cloud Services</h2><br>
                        <div class="col-lg-12 pl-0 pr-0">
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse1}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section3 aboutCompanyBG">
            <div class="container">
                <div class="borderbottomwhite width100 ml-lg-3 ml-2 mb-3"></div>
                <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                    <h3 class="fontsize28 white proximanova-semibold">Our end-to-end Cloud Services include</h3><br>
                </div>
                <div class="d-flex col-12 pl-0 pr-0 flex-wrap">
                    <div class="col-md-6 mt-4 text-left d-flex flex-column">
                        <img src="../../assets/engaging.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h5 class="fontsize20 white proximanova-semibold mt-2">Cloud-Based Application Development </h5>
                        <p class="fontsize16 white proximanova">{{cloudbasedApplicationDevelopment}}</p>
                    </div>
                    <div class="col-md-6 mt-4 text-left d-flex flex-column">
                        <img src="../../assets/creating.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h5 class="fontsize20 white proximanova-semibold mt-2">Cloud Migration Services </h5>
                        <p class="fontsize16 white proximanova">{{cloudMigrationService}}</p>
                    </div>
                    <div class="col-md-6 mt-4 text-left d-flex flex-column">
                        <img src="../../assets/streamline.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h5 class="fontsize20 white proximanova-semibold mt-2">Cloud Integration Services </h5>
                        <p class="fontsize16 white proximanova">{{cloudIntegrationService}}</p>
                    </div>
                    <div class="col-md-6 mt-4 text-left d-flex flex-column">
                        <img src="../../assets/streamline.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h5 class="fontsize20 white proximanova-semibold mt-2">Cloud Consulting Services </h5>
                        <p class="fontsize16 white proximanova">{{cloudConsultingService}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-10 my-auto">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <h2 class="fontsize28 white proximanova-semibold text-left">The Cloud Advantages </h2><br>
                        <div class="col-lg-12 pl-0 pr-0 mt-3 text-left">
                            <h3 class="fontsize24 white proximanova-semibold text-left">Cloud Advantages</h3>
                            <div class="mt-3" v-for="(advantage, index) in cloudAdvantages" :key="index">
                                <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="img-fluid" alt="tickmark">
                                <span class="fontsize16 white proximanova ml-2">{{advantage}}</span>
                            </div>
                        </div>
                        <div class="col-lg-12 pl-0 pr-0 mt-3 text-left mt-5">
                            <h3 class="fontsize24 white proximanova-semibold text-left">Cloud Challenge</h3>
                            <div class="mt-3" v-for="(challenge, index) in cloudChallenge" :key="index">
                                <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="img-fluid" alt="tickmark">
                                <span class="fontsize16 white proximanova ml-2">{{challenge}}</span>
                            </div>
                        </div>
                        <div class="col-lg-12 pl-0 pr-0 mt-3 text-left mt-5">
                            <h3 class="fontsize24 white proximanova-semibold text-left">Key Cloud Characteristics</h3>
                            <div class="mt-3" v-for="(characteristic, index) in keyCharacteristics" :key="index">
                                <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="img-fluid" alt="tickmark">
                                <span class="fontsize16 white proximanova ml-2">{{characteristic}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Cloud Computing Application | How does cloud computing work",
            meta: [
                { name: 'description', content:  "Learn cloud computing application with cloud services, cloud-based application, cloud migration, integration, consulting services, cloud advantages and Many!"},
                { name : 'keywords', content : 'Cloud computing, cloud computing application, what is cloud computing, cloud computing overview, cloud computing technology, cloud computing services.'}
            ]
        }
    },
    data() {
        return {
            aboutCourse1 : 'Cognax tech provides Cloud Services that can be accessed and maintained globally. It can be easily deployed into existing infrastructure, and future enhancements can support new user requirements. Our expertise in constructing a cloud computing environment (private, hybrid, or service provider cloud) utilizing proven technologies for delivering application platform services include all aspects of Cloud adoption, providing the enterprise with enhanced business flexibility at reduced costs.',
            technology : 'We preach, and practice cloud strategy to eliminate barriers to innovation transforms enterprise applications into cost-effective solutions.',
            Clients : 'We have helped enterprises leverage most of their cloud applications across numerous platforms, empowering them with tools the improve efficiency and profitability.',
            Experience : 'We have over 10+ years of experience developing and deploying innovative applications that help you reach a much larger customer base with improved user experience.',
            Services : 'We have helped businesses build solutions that deliver a personalized experience that better engage their customers. Our services extend across various domains and skillsets.',
            cloudbasedApplicationDevelopment : 'We are specialized in planning and building applications for organizations focusing on the cloud for business needs. We ensure better reliability and efficiency through our scalable cloud testing services. We assess the application to strategize the development approach by adapting standardized methodologies implementing an automated process for validating and delivering high-quality output.',
            cloudMigrationService : "We offer a well-organized service that changes legacy and custom applications within a limited capacity to run in the cloud. We are specialized in migrating applications from enterprise data centers to modern cloud application platforms. Simultaneously, other conditional applications will also be tried for cloud adaptability, and a scheduled modification approach will be put forth to establish an integrated cloud ecosystem.",
            cloudIntegrationService : "We provide assistance integrating multiple services to ensure that data and processes sync across the enterprise’s on-premises and cloud services. We also address the overall organizational needs and the unique change management needs of the enterprise.",
            cloudConsultingService : 'We provide continuous consultation and support to clients for analyzing the current scenario to recognize new opportunities to leverage the cloud. Importantly, we additionally put forward activities including identifying an appropriate cloud deployment model, initiating a roadmap for cloud adoption, and planning a methodology towards cloud enablement.',
            cloudAdvantages : [
                'Establish new business processes and launch products in the latest technologies',
                'Enhance productivity by decreasing build costs and process time to utilize resources.',
                'Single-center of accountability and service assurance.',
                'Creating a benchmark for software applications and business processes throughout the enterprise.'
            ],
            cloudChallenge : [
                'Grow business values by decreasing IT operations expenses.',
                'Planning a detailed approach and roadmap for transforming cloud, ensuring maximum trade value.',
                'Technical barrier identification and visualization are required for creating a cloud solution.',
                'Low budgets and unstable markets demand quick execution of projects through innovative and planned processes.',
                'Skilled resources have previous exposure to implementing cloud solutions efficiently.'
            ],
            keyCharacteristics : [
                'On-demand self-service',
                'Ubiquitous network access',
                'Location independent resource pooling',
                'Rapid elasticity',
                'Pay per use'
            ]
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .8), rgba(31,31,31,.8)), url('../../assets/cloudComputingapp.png');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 200px 0;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.aboutCompanyBG{
    background-image: linear-gradient(rgba(44, 62, 80, .9), rgba(44, 62, 80, .9)), url('../../assets/cloudComputingapp2.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>