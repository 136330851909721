<template>
    <div class="">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">Internet Of Things </h1>
                    </div>
                    <p class="proximanova fontsize24 white body-copy text-left">Building a connected and secure world.</p>
                </div>
                <div class="d-flex col-12 pl-0 pr-0 flex-wrap mt-5">
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/database.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">TECHNOLOGY</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{technology}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/clients.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">CLIENTS</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Clients}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/experince.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">EXPERIENCE</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Experience}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/services.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">SERVICES</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Services }}</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-10 my-auto">
                        <div class="col-lg-12 pl-0 pr-0">
                            <p class="proximanova-semibold fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse1}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse2}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section3 aboutCompanyBG">
            <div class="container">
                <div class="borderbottomwhite width100 ml-lg-3 ml-2 mb-3"></div>
                <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                    <h2 class="fontsize28 white proximanova-semibold">Building Business Transformation with IoT </h2><br>
                </div>
                <div class="d-flex col-12 pl-0 pr-0 flex-wrap">
                    <div class="col-lg-4 col-md-6 mt-3 text-left d-flex flex-column">
                        <img src="../../assets/engaging.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h2 class="fontsize20 white proximanova-semibold mt-2">Engaging Customer Experience </h2>
                        <span class="fontsize16 white proximanova-light mt-3">{{engaging}}</span>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-3 text-left d-flex flex-column">
                        <img src="../../assets/creating.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h2 class="fontsize20 white proximanova-semibold mt-2">Creating Business Innovation</h2>
                        <span class="fontsize16 white proximanova-light mt-3">{{creating}}</span>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-3 text-left d-flex flex-column">
                        <img src="../../assets/streamline.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h2 class="fontsize20 white proximanova-semibold mt-2">Streamline Business with Optimized Processes </h2>
                        <span class="fontsize16 white proximanova-light mt-3">{{streamline}}</span>
                    </div>
                </div>
                <div class="mt-4 text-left pl-2 pr-2">
                    <h3 class="fontsize24 white proximanova-semibold mb-4">Partner with Cognax tech </h3>
                    <span class="fontsize16 white proximanova-light">{{partners}}</span>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Internet of Things Application | What is IoT | IoT Technology",
            meta: [
                { name: 'description', content:  'Learn how Internet of Things (IoT) transforms your business, how IoT can benefit organizations, pros and cons of IoT and Building Business Transformation with IoT.'},
                { name : 'keywords', content : 'IoT, what is IoT, Benefits of IoT, Internet of Things, IoT Technology, what is Internet of Things, Internet of Things overview IoT Services.'}
            ]
        }
    },
    data() {
        return {
            aboutCourse1 : 'It becomes increasingly difficult to evaluate various technology options with an existing business solution. This is a big challenge, especially for a start-up firm or firm where technology is not the core competency. Cognax tech’s experienced business technology consultants will guide you with strategic advice on technology, helping you decide on advanced solutions that would be cost-effective and highly productive for your business process.',
            aboutCourse2 : 'Cognax tech helps you adopt the right IT Strategy for your business and craft simple solutions to drive your business objectives seamlessly. Moreover, our delivery practices enable us to adjust our solutions for your business objectives. With their share of knowledge in many industry verticals and expertise, our Analysts help in the efficient implementation of projects and create systems that bring value to your IT investments.',
            technology : 'Our expertise across domains and technologies empowers us to create a seamless IoT portfolio that associates people, processes, and technology.',
            Clients : 'We have helped numerous real estate, healthcare, retail, and media clients deploy smart IoT products and practices.',
            Experience : 'We have extensive experience working with beacons, sensors, and OBD devices that help clients redefine how they engage with and monitor their customers.',
            Services : 'Our services center around deploying robust IoT environments. We assist organizations in analyzing, planning, prototyping, and deploying IoT models and revenue streams.',
            engaging : 'Cognax tech’s IoT Suite empowers you to transform raw data into actionable insights. Know your customer behavior, anticipate market trends better, and build on customer loyalty.',
            creating : "New thoughts are born when you work with new partners, new technologies, new resources, and new data streams. IoT can move your enterprise forward by increasing agility, innovation, productivity, and efficiency.",
            streamline : "Transparency is key in building smarter business processes. Monitor all your employees across multiple departments and locations. Analyze business operations and results regardless of business location.",
            partners : 'We at Cognax tech, ensure that IoT works in the business context of our clients. Cognax tech delivers technologies to enable your IoT solutions— cloud, networks and gateways, heterogeneous device support, systems capabilities, and data analytics. We provide industry-specific solutions that improve productivity and operational efficiency with exceptional reliability and security.'
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .85), rgba(31,31,31,.85)), url('../../assets/IOT1.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 200px 0;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.aboutCompanyBG{
    background-image: linear-gradient(rgba(44, 62, 80, .9), rgba(44, 62, 80, .9)), url('../../assets/IOT.png');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>