<template>
    <div class="edgeAi">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">Life Sciences Industry</h1>
                    </div>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description1}}</p>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description2}}</p>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-7 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h2 class="fontsize24 white proximanova-semibold text-left my-auto">What is Life Sciences Industry?</h2>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{whatisLifeSciences1}}</p>
                        <img src="../../assets/lifeSciences1.jpg" alt="manufacturing industries" class="img-fluid mt-2 mb-2 d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                        <p class="proximanova fontsize18 white body-copy text-left">{{whatisLifeSciences2}}</p>
                    </div>
                    <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                        <img src="../../assets/lifeSciences1.jpg" alt="manufacturing industries" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h2 class="fontsize24 white proximanova-semibold text-left my-auto">Top Trends in the life sciences Industry </h2>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{trendsInLifeScience}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-7 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">Collaborative innovation of various life sciences companies</h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{collaboration1}}</p>
                        <img src="../../assets/lifeSciences2.jpg" alt="manufacturing industries" class="img-fluid mt-2 mb-2 d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                        <p class="proximanova fontsize18 white body-copy text-left">{{collaboration2}}</p>
                    </div>
                    <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                        <img src="../../assets/lifeSciences2.jpg" alt="manufacturing industries" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-7 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">Advancement of drug research</h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{drugResearch1}}</p>
                        <img src="../../assets/lifeSciences3.jpg" alt="manufacturing industries" class="img-fluid mt-2 mb-2 d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                        <p class="proximanova fontsize18 white body-copy text-left">{{drugResearch2}}</p>
                    </div>
                    <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                        <img src="../../assets/lifeSciences3.jpg" alt="manufacturing industries" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">The increased volume of life sciences research</h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{increasedVolume1}}</p>
                        <p class="proximanova fontsize18 white body-copy text-left">{{increasedVolume2}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">Value-based pricing of products</h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{valuebased1}}</p>
                        <p class="proximanova fontsize18 white body-copy text-left">{{valuebased2}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">Data Management and Integration</h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{datamanagement}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">Improvement of drug approval timelines</h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{drugapproval1}}</p>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{drugapproval2}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">Incorporation of genetics in treatment</h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{incorporation1}}</p>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{incorporation2}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-12 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">Heightened focus on immune cell function</h4>
                        </div>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{immuneCellFunction1}}</p>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{immuneCellFunction2}}</p>
                        <p class="proximanova fontsize18 white body-copy text-left mt-4">{{immuneCellFunction3}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-center">
                        <div class="borderbottomwhite width100 mb-3 mx-auto"></div>
                        <h3 class="fontsize28 white proximanova-semibold">Conclusion</h3>
                        <p class="fontsize16 white proximanova-light mt-3">{{conclusion}}</p>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Life Sciences | Complete Explanation about Life Sciences Industry ",
            meta: [
                { name: 'description', content:  'Life science industry: What is life science industry, Top trends in life science industry, Advancement of drug research, Value-based pricing of products and many more!'},
                { name : 'keywords', content : 'Life Sciences, Life sciences industry, Life sciences sector, Introduction to life sciences, Life sciences overview, Life sciences technology.'}
            ]
        }
    }, 
    data() {
        return {
            description1 : "Every day, we see the world continuously changing. Whether it's advanced in technology, culture, arts, or sciences, there are many things to look forward to over the horizon.",
            description2 : 'One of the industries that are constantly experiencing breakthroughs in the life sciences. The life sciences have always been dedicated to protecting and improving human, animal, and natural life. Before we research the newest discoveries in the life sciences, let us define them.',
            whatisLifeSciences1 : "The life sciences industry consists of companies operating in pharmaceuticals, biotechnology, medical devices, biomedical technologies, nutraceuticals, cosmeceuticals, food processing, and others that dedicate their efforts to creating products to improve the lives of organisms.",
            whatisLifeSciences2 : "At Preclinical, we concentrate on the health and medical factor of life sciences, which protects pharmaceuticals, biotechnology, medical devices, digital health, and other helping firms such as CROs/CMOs that are determined to design, build, and commercialize innovative treatments, diagnostic equipment, tools and software to enhance and extend lives.",
            trendsInLifeScience : "As the life sciences industry's research body becomes increasingly expansive, many experts discover that genetic coding inherits some conditions. This implies that healthcare can be customized based on DNA information or other genome features. This directs a new era of personalized medicines and healthcare procedures.",
            collaboration1 : "The life sciences industry is constantly growing thanks to its collective nature. In 2022, we can expect biotech companies to join forces with other affiliated health sciences organizations to push the limitations for growth. An instance of this occurred in May of 2019 when four companies, namely Arzeda, Twist Bioscience, Labcyte, and TeselaGen, have partnered to develop a state-of-the-art assembly platform for the DNA.",
            collaboration2 : "This objective is to create food items such as sweeteners and other industrial demands. Hopefully, this will reduce the need for harvesting or mining supplies as DNA will be made in laboratories.",
            drugResearch1 : "Another rising movement is the advancement of drug research. Innovative technology lets the possibility of enhancing the assessment and therapy of patients using medication.",
            drugResearch2 : "Some tools that will enhance are combining assessment devices such as MRI scans, laboratory tools, and in-patient monitors and managing tools. Doctors will integrate the data needed to define the proper remedy for the patient. This delivers accuracy and adherence to the objective information provider by the assessment tools rather than subjective reports.",
            increasedVolume1 : "Many students have concentrated on various life science departments, such as immunotherapy, precision surgery, and genetics.",
            increasedVolume2 : "There is a rise in studies combining robotics to improve surgical methods in precision surgery. Surgeons can now benefit from artificial intelligence tools to perform appropriate procedures on their patients. Artificial intelligence also delivers insight for doctors regarding the course of treatment.",
            valuebased1 : "This rising trend is good information for consumers, as better rules are being given to drive down the prices of products, particularly in the pharmaceutical and nutraceutical industries.",
            valuebased2 : "More companies are investing in showing actual demonstrations of drug effectiveness so that consumers can see the product's real value. This can help drive down the cost of medications while providing evidence-based knowledge for essential stakeholders.",
            datamanagement : "In the early stages of data management, it was difficult for data analysts to gather, organize, and interpret pertinent information for life sciences research and system operations. The innovation of cloud management will enable data management professionals to collect and analyze data for improved life science practices efficiently.",
            drugapproval1 : "Much-needed remedies are being maintained close due to the long process of drug approvals in the FDA. However, technical advances will help government bodies enhance drug testing speed and control candidates' trials.",
            drugapproval2 : "An example of this is the Real-Time Oncology Review (RTOR) pilot, which is aspired to accelerate the drug approvals associated with cancer treatment.",
            incorporation1 : "There will also be an increase in integrating genetic information in assessing and treating disorders. Additionally, gene technology will enable researchers to identify genome sequences that predict diseases in humans and animals.",
            incorporation2 : "There are currently experiments in gene editing that could potentially prevent diseases and other conditions that can be inherited from the mother to the child. These studies will continue to rise as more prerequisites are genetic.",
            immuneCellFunction1 : "Immunity is one exciting aspect of the life sciences. It could prevent the spreading of harmful diseases, bringing about a devastating epidemic, especially in developing regions. Now, there is an augmented focus on immune cell function. Researchers are interested in unique mechanisms of cells that are immune to specific types of viruses and bacteria. These studies will bring about profound importance in the field of immunotherapy. Current studies show that immune cells get most of their aspects through their mitochondria.",
            immuneCellFunction2 : "These are just some of the recent breakthroughs in the life sciences industry, and there is more to come as countries like the United States include improved funding in research areas of science, technology, and healthcare.",
            immuneCellFunction3 : "The changes carried by these creations remind us that the life sciences industry is a crucial part of our society. We must accomplish our best to push its frontiers ahead for the sake of our interest and the years to come.",
            conclusion : "Life sciences organizations face typical challenges throughout the finding, growth, and commercialization procedures, including exposure to a high degree of risk. Increasingly strict global restrictions imply compliance must remain at the forefront of day-to-day activities, and the penalty for violations goes beyond finances. Because life sciences organizations work affects human beings, trust and reputation management are essential aspects of their risk management plan."
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .5), rgba(31,31,31,.5)), url('../../assets/lifeSciences.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 100px 0;
}
/* section2 related styles */
.section2{
    padding-top: 4% !important;
    padding-bottom: 4% !important;
    /* background-color: white; */
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>