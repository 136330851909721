<template>
    <div class="">
        <section class="section111">
            <div class="container-fluid">
                <div class="col-lg-12 d-lg-flex my-auto text-left justify-content-around">
                    <div class="col-lg-6 pl-0 pr-0">
                        <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5 mt-lg-4">
                            <h1 class="fontsize40 white proximanova-semibold">Contact us </h1>
                        </div>
                        <p class="proximanova fontsize24 white body-copy text-left mb-3">{{descriptionContent}}</p>
                        <div class="borderwhite col-lg-8 p-3 shadow borderadius16 mt-4 mt-lg-5 mb-4">
                            <h5 class="fontsize24 white proximanova-semibold">Address : </h5>
                            <p class="proximanova fontsize18 white body-copy text-left">Stonebriar Center 7460 Warren Parkway Suite 100 , Frisco, TX 75034</p>
                            <div class="borderbottomwhite2px"></div>
                            <!-- <div class="d-flex mt-3">
                                <img src="../assets/NewMapview.svg" width="25px" height="25px" alt="map view">
                                <a href="https://www.google.com/maps/place/Regus+-+Texas,+Frisco+-+Stonebriar+Center/@33.1082007,-96.8222705,17z/data=!3m2!4b1!5s0x864c3c9967149d73:0xbddd4bd06d84ccc7!4m5!3m4!1s0x864c3c8e67a00193:0xed454d2841c68dc5!8m2!3d33.1082007!4d-96.8200818" 
                                target="_black"  class="link proximanova fontsize16 white body-copy text-left ml-4">Map View</a>
                            </div> -->
                            <div class="d-flex mt-3">
                                <img src="../assets/NewEmail.svg" width="25px" height="25px" alt="map view">
                                <span class="proximanova fontsize16 white body-copy text-left ml-4">info@cognaxtech.com</span>
                            </div>
                            <div class="d-flex mt-3">
                                <img src="../assets/NewPhone.svg" width="25px" height="25px" alt="map view">
                                <span class="proximanova fontsize16 white body-copy text-left ml-4">+1 430-558-1922</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 pl-0 pr-0">
                        <div class="card shadow col-12 p-4 borderadius16">
                            <div class="d-flex flex-column mb-3">
                                <span class="fontsize24 proximanova-semibold black text-left">ContactUs</span>
                            </div>
                            <div>
                                <div class="col-12 d-md-flex pl-0 pr-0 ">
                                    <div class="d-flex input-icons col-md-6 pl-0 pr-0 mt-2" :class="{'borderred' :errors.has('cognaxcontact.First Name/Last Name')}">
                                        <img src="../assets/contact_name.png" class="icon black my-auto" alt="Firstname">
                                        <input class="input-field" type="text"  v-validate="'required'" name="First Name/Last Name" data-vv-as="First Name/Last Name" data-vv-scope="cognaxcontact" v-model="firstName" placeholder="First Name">
                                    </div>
                                    <div class="input-icons lastName col-md-6 pr-0 pl-0 mt-2 d-flex" :class="{'borderred' :errors.has('cognaxcontact.First Name/Last Name')}">
                                        <img src="../assets/contact_name.png" class="icon black d-block d-md-none my-auto" alt="Lastname">
                                        <input class="input-field" type="text" 
                                        v-validate="'required'" name="First Name/Last Name" data-vv-as="First Name/Last Name" data-vv-scope="cognaxcontact" v-model="lastName" placeholder="Last Name">
                                    </div>
                                </div>
                                <div v-if="errors.has('cognaxcontact.First Name/Last Name')" class="alert-danger col-12 text-right">{{ errors.first('cognaxcontact.First Name/Last Name') }}</div>
                            </div>
                            <div>
                                <div class="input-icons mt-2 d-flex" :class="{'borderred' :errors.has('cognaxcontact.Email')}">
                                    <img src="../assets/contact_email.png" class="icon black my-auto" alt="Email">
                                    <!-- <input class="input-field" type="email" id="email"
                                    v-validate="'required'" name="Work Email" data-vv-as="Work Email" data-vv-scope="cognaxcontact" v-model="userEmail" autocomplete="off" placeholder="Work email"> -->
                                    <input type="email" id="email" v-validate="'required'" name="Email" data-vv-as="Email"
                                        data-vv-scope="cognaxcontact" v-model="userEmail" class="input-field" autocomplete="off" placeholder="Enter Work Email id" > 
                                </div>
                                <div v-if="errors.has('cognaxcontact.Email')" class="alert-danger col-12 text-right">{{ errors.first('cognaxcontact.Email') }}</div>
                            </div>
                            <div>
                                <div class="input-icons mt-2 d-flex" :class="{'borderred' :errors.has('cognaxcontact.Company Name')}">
                                    <img src="../assets/contact_company.png" class="icon black my-auto" alt="Company name">
                                    <input class="input-field" type="text"
                                    v-validate="'required'" name="Company Name" data-vv-as="Company Name" data-vv-scope="cognaxcontact" v-model="companyName" placeholder="Company Name">
                                </div>
                                <div v-if="errors.has('cognaxcontact.Company Name')" class="alert-danger col-12 text-right">{{ errors.first('cognaxcontact.Company Name') }}</div>
                            </div>
                            <div>
                                <div class="input-icons mt-2 d-flex" id="phonenumberfield" :class="{'borderred' :errors.has('cognaxcontact.Phone Number')}">
                                    <img src="../assets/contact_phone1.png" class="icon black my-auto" alt="phone number">
                                    <input class="input-field" type="text" id="phoneNumber" 
                                    v-validate="'required'" name="Phone Number" data-vv-as="Phone Number" data-vv-scope="cognaxcontact" placeholder="9********9" maxlength="10" autocomplete="off" v-model="phoneNumber">
                                </div>
                                <div v-if="errors.has('cognaxcontact.Phone Number')" class="alert-danger col-12 text-right">{{ errors.first('cognaxcontact.Phone Number') }}</div>
                            </div>
                            <div>
                                <div class="input-icons mt-2 d-flex" :class="{'borderred' :errors.has('cognaxcontact.Designation')}">
                                    <img src="../assets/contact_designation.png" class="icon black my-auto" alt="designation">
                                    <input class="input-field" type="text" 
                                    v-validate="'required'" name="Designation" data-vv-as="Designation" data-vv-scope="cognaxcontact" v-model="userOccupation" placeholder="Designation">
                                </div>
                                <div v-if="errors.has('cognaxcontact.Designation')" class="alert-danger col-12 text-right">{{ errors.first('cognaxcontact.Designation') }}</div>
                            </div>
                            <div>
                                <div class="input-icons mt-2 d-flex" :class="{'borderred' :errors.has('cognaxcontact.Location')}">
                                    <img src="../assets/contact_location.png" class="icon black my-auto" alt="location">
                                    <select id="Location" class="countrycode"
                                    v-validate="'required'" name="Location" data-vv-as="Location" data-vv-scope="cognaxcontact"  v-model="countryName">
                                        <option value="" disabled selected>Location</option>
                                        <option v-for="(cc,index) in countryCodes"  class="option" :value="cc.country" :key="index"> {{cc.country}}</option>
                                    </select>
                                </div>
                                <div v-if="errors.has('cognaxcontact.Location')" class="alert-danger col-12 text-right">{{ errors.first('cognaxcontact.Location') }}</div>
                            </div>
                            <div>
                                <div class="input-icons mt-2 d-flex" :class="{'borderred' :errors.has('cognaxcontact.Service')}">
                                    <img src="../assets/contact_services.png" class="icon black my-auto" alt="service">
                                    <select id="Service" class="countrycode"
                                    v-validate="'required'" name="Service" data-vv-as="Service" data-vv-scope="cognaxcontact" v-model="CourseName">
                                        <option value="" disabled selected>Services</option>
                                        <option v-for="(cc,index) in listItems"  class="option" :value="cc" :key="index"> {{cc}}</option>
                                    </select>
                                </div>
                                <div v-if="errors.has('cognaxcontact.Service')" class="alert-danger col-12 text-right">{{ errors.first('cognaxcontact.Service') }}</div>
                            </div>
                            <div class="input-icons mt-2 d-flex">
                                <img src="../assets/contact_description.png" class="icon black" alt="description">
                                <textarea name="Description" class="input-field" placeholder="Description"  v-model="Description" id="" cols="30" rows="3"></textarea>
                            </div>
                            <div class="mt-3">
                                <button class="submitbutton" v-if="!submitBtnClicked" @click.prevent="ContactUsSubmit">Submit</button>
                                <img src="../assets/uploadvideo.svg" class="loadingIcon img-fluid mt-2 ml-4 mr-4" v-else alt="Loading">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal-backdrop"  v-if="successFormSubmission">
            <div class="card maincard col-lg-4 col-md-6 mt-md-4 col-11 p-0">
                <div class="modal-content postmodalcontent">
                    <div class="modal-header">          
                    <h5 class="modal-title font16-bold ml-2" id="exampleModalLabel"></h5>  
                    <button type="button" class="closebtn" @click="closeModal" data-dismiss="modal" aria-label="Close">&times;
                    </button>
                    </div>
                    <div class="modal-body p-2 pb-5">
                    <div class="d-flex flex-column text-center">
                        <img src="../assets/check_green.gif" class="col-3 img-fluid mx-auto" alt="Success">
                        <span class="mt-4 proximanova-bold fontsize18 black">You request has been successfully submitted.</span>
                        <span class="mt-2 proximanova fontsize16 black80">A Careertuner team member will contact you shortly.</span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop" v-if="errorFormSubmission">
            <div class="card maincard col-lg-4 col-md-6 mt-md-4 col-11 p-0">
                <div class="modal-content postmodalcontent">
                    <div class="modal-header">          
                    <h5 class="modal-title font16-bold ml-2" id="exampleModalLabel"></h5>  
                    <button type="button" class="closebtn" @click="closeModal" data-dismiss="modal" aria-label="Close">&times;
                    </button>
                    </div>
                    <div class="modal-body p-2 pb-5">
                    <div class="d-flex flex-column text-center">
                        <img src="../assets/information.gif" class="col-3 img-fluid mx-auto" alt="Error">
                        <span class="mt-4 proximanova-bold fontsize16 black">Something went wrong.</span>
                        <span class="mt-2 proximanova fontsize16 black80">Try after some time.</span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import httpContact1 from '../js/http-cognaxtech.js'
import Footer from '../components/footer.vue';
export default {
    data() {
        return {
            firstName : null,
            lastName : null,
            userEmail : null,
            companyName : null,
            phoneNumber : null,
            userOccupation : null,
            countryName : 'India',
            CourseName : 'Internet Of Things',
            Description : '',
            validPhonenumer : false,
            validEmail : false,
            submitBtnClicked : false,
            successFormSubmission : false,
            errorFormSubmission : false,
            descriptionContent : 'Are you looking for an innovation partner to assist you with IT services such as web development, mobile application development, or software consulting? Talk to our expert team today about your business requirement.',
            countryCodes :  [
                {country : 'USA'},
                {country : 'India'}
            ],
            listItems : [
                'Internet Of Things',
                'Web Application Development',
                'Business Technology Consulting',
                'Cloud Computing Application',
                'IOS Application Development',
                'Android Application Development',
                'Hybrid Application Development',
                'Recruiting'
            ],
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        closeModal() {
            this.submitBtnClicked = false;
            this.successFormSubmission = false;
            this.errorFormSubmission = false;
        },
        clearData(){
            this.firstName = null;
            this.lastName = null;
            this.userEmail = null;
            this.companyName = null;
            this.phoneNumber = null;
            this.userOccupation = null;
            this.countryName = 'India';
            this.CourseName = 'Internet Of Things';
            this.Description = ''
        },
        ContactUsSubmit() {
            var that = this;
            this.$validator.validateAll("cognaxcontact").then((isValid) => {
            // if(this.validPhonenumer && this.validEmail) {
            if(isValid){
                var phoneNum ;
                if(this.countryName == 'India'){
                    phoneNum = '(+91) '+this.phoneNumber
                }else{
                    phoneNum = '(+1) '+this.phoneNumber
                }
                this.submitBtnClicked = true;
                let obj = {
                    firstName : this.firstName,
                    lastName : this.lastName,
                    email : this.userEmail,
                    companyName : this.companyName,
                    phoneNumber : phoneNum,
                    occupation : this.userOccupation,
                    country : this.countryName,
                    enquiryType : this.CourseName,
                    scheduleNotes : this.Description,
                    flagType : "cognaxtech contact"
                }
                console.log("contact object : ", obj)
                //let phoneNumber = '('+ this.countrycode +')' + this.phoneNumber        
                //httpContact.post('/contactUSCTIO/saveManageContact',)
                httpContact1.post('/contactUS/saveCognaxtechContactData', obj)
                .then((response) => {
                    console.log("response : ", response)
                    this.submitBtnClicked = false;
                    this.errorFormSubmission = false;
                    this.successFormSubmission = true;
                    setTimeout(() => {
                        that.closeModal()
                        that.clearData()
                    }, 3000);        
                }).catch((e) => {
                    console.log(e)
                    this.submitBtnClicked = false;
                    this.successFormSubmission = false;
                    this.errorFormSubmission = true;
                    console.log(e)
                    setTimeout(() => {
                        that.closeModal()   
                    }, 5000);
                })
             }
            //else{
            //     if(!this.validEmail){
            //     alert("Please enter valid Email")
            //     }
            //     if(!this.validPhonenumer){
            //     alert("Please enter valid phone number")
            //     }
            // }
            })
        },
    }
}
</script>
<style lang="css" scoped>
.modal-backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000 !important;
    padding-top: 3%;
}
.closebtn {
  width: 30px;
  height: 30px;
  border:none;
  border-radius: 50%;
}
.closebtn:hover{
  /* background: none !important; */
  border: 1px solid black;
  border-radius: 50%;
}
.modal-header {
  border-bottom: none;
}
.maincard{
  border: none !important;
}
.loadingIcon{
  width: 34px;
  height: 34px;
  margin-left: 45% !important;
}
/* section1 related styles */
.section111{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .7), rgba(31,31,31,.7)), url('../assets/contactus.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 200px 0;
}
.link:hover {
    text-decoration: none;
}
.border-red{
  border: 1px solid red !important;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}
/* form */
.input-icons i {
    position: absolute;
}
.input-icons {
    width: 100%;
    border: 1px solid black;
    border-radius: 5px !important;
}
.icon {
    padding: 5px;
    /* margin-top: 1px !important; */
    /* min-width: 40px; */
    width: 30px;
    height: 30px;
}  
select{
    width: 100%;
    padding: 8px;
    /* padding-left: 35px; */
    text-align: left;
    font-size: 14px;
    border: none;
    border-radius: 5px !important;
}
.input-field {
    width: 100%;
    padding: 8px;
    /* padding-left: 35px; */
    text-align: left;
    font-size: 14px;
    border: none;
    border-radius: 5px !important;
}
.input-field:focus, select:focus{
    outline: none;
}
.submitbutton{
    background: black;
    color: white;
    border-radius: 5px;
    border: none;
    width: 100%;
    padding: 10px;
}
@media screen and (min-width : 500px) {
    .section111{
        padding-left: 6% !important;
        padding-right: 6% !important;
    }
    .lastName{
        margin-left: 1px;
    }
}
@media screen and (max-width : 500px) {
    .lastName{
        padding-left: 0 !important;
    }
}
@media screen and (min-width : 770px) {
    .section111 {
        height: 800px !important;
    }
}
@media screen and  (max-width : 600px){
  .maincard{
    margin-left: 4%;
    margin-top: 4%;
  }
  .loadingIcon{
    width: 32px;
    height: 32px;
  }
}
@media screen and  (min-width: 770px){
  .maincard{
    margin-left: 35%;
  }
}
@media screen and  (min-width: 600px) and (max-width : 768px){
  .maincard{
    margin-left: 15%;
  }
}
</style>