<template>
    <div class="">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">Android Application Development </h1>
                    </div>
                    <p class="proximanova fontsize24 white body-copy text-left">Do more with an engaging and improving android application.</p>
                </div>
                <div class="d-flex col-12 pl-0 pr-0 flex-wrap">
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/database.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">TECHNOLOGY</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{technology}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/clients.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">CLIENTS</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Clients}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/experince.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">EXPERIENCE</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Experience}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/services.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">SERVICES</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Services }}</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-10 my-auto">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <h3 class="fontsize28 white proximanova-semibold text-left">Our Android Services</h3><br>
                        <div class="col-lg-12 pl-0 pr-0">
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse1}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse2}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse3}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse4}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse5}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section3 aboutCompanyBG">
            <div class="container text-left">
                <div class="borderbottomwhite width100 ml-lg-3 ml-2 mb-3"></div>
                <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                    <h4 class="fontsize28 white proximanova-semibold">Mobile Strategies </h4><br>
                </div>
                <div class="pl-md-3 pr-md-3">
                    <p class="fontsize20 white proximanova-light">{{mobileStrategies}}</p>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Android Application Development | The Best Android App Developers",
            meta: [
                { name: 'description', content:  "Android App Development: We offer custom based android application development services created by highly skilled android developers to help your business grow."},
                { name : 'keywords', content : 'Android application development, Android app development, Android developers, Android Development, Android software development.'}
            ]
        }
    },
    data() {
        return {
            aboutCourse1 : 'Android application ecosystem is one of the biggest mobile application ecosystems today. Building a customized mobile application is essential to stand out in such a crowded ecosystem. At cognax tech, we address this challenge by building Android applications tailored to meet business objectives and end-user needs',
            aboutCourse2 : 'With such a large consumer base, your android application needs to acquire uniqueness with your customers. An organization’s android application should be the foundation of customer engagement that can help build long-term customer loyalty. We accept that customization is the key to this. Our team works with Android Software Development Kit (SDK), Android Media APIs, Location-based Service APIs, Wi-Fi APIs, Android Security Architecture, OpenGL, 3D illustrations, and other technologies required to build innovative Android applications.',
            aboutCourse3 : 'At cognax tech, we are committed to delivering exceptionally high-quality professional services by adding value to your business, customers, and employee. Cognax tech’s mobile development team analyses your business challenges and develops a customized solution that fits the organization’s needs.',
            aboutCourse4 : 'Our dedicated team of skilled Android UX/UI designers, developers and mobile strategists provide a customer-centric application that is easy to use for your customers. Our expert developers also focus on creating an application for organization employees that can help increase their productivity. Cognax tech developers are with your organization throughout, ensuring that you get an accurate, efficient, productive, and profitable application for your business.',
            aboutCourse5 : 'Have an Application Idea? Please share it with us and experience our quality & trustworthy service today! Our mobile programmers have developed various Android Mobile Applications, including native Android Applications, Android compatible Web-Based Applications and Hybrid Applications.',
            technology : 'Our Android development team works vastly with Android SDK, PhoneGap, XML, CSS, JavaScript, and HTML5. ',
            Clients : 'Our android solutions empower clients with scalable, secure, and innovative applications that enable organizations to improve efficiency and profitability.',
            Experience : 'We have worked with leading enterprises such as PWC, CBN, etc, and helped them deliver customized business applications that help organizations meet business goals.',
            Services : 'Our expert team can help deliver customized, secure, and strong native mobile applications, including e-commerce, augmented reality, and integration applications.',
            mobileStrategies : 'For your business, it’s not only about a mobile app but an integrated solution that delivers a seamless experience. We help you integrate your technology need with business strategies to achieve business success. Our Mobile techniques can create added value to benefit businesses and consumers. The requirement is to gain maximum benefit from mobile technologies.',
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .86), rgba(31,31,31,.86)), url('../../assets/android1.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 200px 0;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.aboutCompanyBG{
    background-image: linear-gradient(rgba(44, 62, 80, .9), rgba(44, 62, 80, .9)), url('../../assets/android1.png');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
/* section3 related styles */
.section3{
    padding-top: 11% !important;
    padding-bottom: 11% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>