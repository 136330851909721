<template>
    <div class="edgeAi">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">E-Learning</h1>
                    </div>
                    <p class="proximanova fontsize18 white body-copy text-left">{{description1}}</p>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="borderbottomwhite width100 mb-3"></div>
                <h2 class="fontsize24 white proximanova-semibold text-left my-auto">Importance of E-Learning</h2>
                <div class="mt-3 text-left">
                    <div class="d-flex mt-3" v-for="(item, index) in importanceList" :key="index">
                        <div>
                        <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="" alt="tickmark">
                        </div>
                        <span class="fontsize16 white proximanova ml-3">{{item}}</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="borderbottomwhite width100 mb-3"></div>
                <h2 class="fontsize24 white proximanova-semibold text-left my-auto">Benefits of E-Learning in Education</h2>
                <div class="mt-3 text-left">
                    <div class="d-flex mt-3" v-for="(item, index) in benefitsList" :key="index">
                        <div>
                        <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="" alt="tickmark">
                        </div>
                        <span class="fontsize16 white proximanova ml-3">{{item}}</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-left">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <h3 class="fontsize30 white proximanova-semibold text-left">How is e-learning used in education?</h3>
                        <p class="fontsize16 white proximanova-white mt-3">{{howElearning}}</p>
                        <div class="col-lg-12 pl-0 pr-0 d-lg-flex mt-5">
                            <div class="col-lg-7 pl-0 pr-0">
                                <div class="d-flex">
                                    <img src="../../assets/right_arrow.png" alt="right arrow" class="img-fluid" width="30px" height="30px">
                                    <h5 class="fontsize24 white proximanova-semibold text-left my-auto ml-3">Adult E-Learning</h5>
                                </div>
                                <p class="fontsize16 white proximanova-white mt-3">{{adultElearning}}</p>
                            </div>
                            <div class="col-md-8 pl-0 pr-0 pl-md-3 pr-md-3 mx-auto col-lg-5 my-auto">
                                <img src="../../assets/adultElearning.jpg" alt="manufacturing industries" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-lg-12 pl-0 pr-0 d-lg-flex mt-5">
                            <div class="col-lg-7 pl-0 pr-0">
                                <div class="d-flex">
                                    <img src="../../assets/right_arrow.png" alt="right arrow" class="img-fluid" width="30px" height="30px">
                                    <h5 class="fontsize24 white proximanova-semibold text-left my-auto ml-3">Corporative E-Learning</h5>
                                </div>
                                <p class="fontsize16 white proximanova-white mt-3">{{carporateEleaning1}}</p>
                                <img src="../../assets/corporativeElearning.jpg" alt="manufacturing industries" class="img-fluid d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                                <p class="fontsize16 white proximanova-white mt-3">{{carporateEleaning2}}</p>
                            </div>
                            <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                                <img src="../../assets/corporativeElearning.jpg" alt="manufacturing industries" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-lg-12 pl-0 pr-0 d-lg-flex mt-5">
                            <div class="col-lg-7 pl-0 pr-0">
                                <div class="d-flex">
                                    <img src="../../assets/right_arrow.png" alt="right arrow" class="" width="30px" height="30px">
                                    <h5 class="fontsize24 white proximanova-semibold text-left my-auto ml-3">Online Colleges</h5>
                                </div>
                                <p class="fontsize16 white proximanova-white mt-3">{{onlineColleges}}</p>
                            </div>
                            <div class="col-md-8  pl-0 pr-0 pl-md-3 pr-md-3 mx-auto col-lg-5 my-auto">
                                <img src="../../assets/onlineColleges.jpg" alt="manufacturing industries" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container text-left">
                <div class="col-lg-12 d-lg-flex mt-5">
                    <div class="col-lg-7 pl-0 pr-0 text-left">
                        <div class="d-flex flex-column">
                            <div class="borderbottomwhite width100 mb-3"></div>
                            <h4 class="fontsize24 white proximanova-semibold text-left my-auto">How is online learning going to look like in the future? </h4>
                        </div>
                        <p class="fontsize16 white proximanova-white mt-3">{{future1}}</p>
                        <img src="../../assets/future.jpg" alt="manufacturing industries" class="img-fluid d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                        <p class="fontsize16 white proximanova-white mt-3">{{future2}}</p>
                    </div>
                    <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                        <img src="../../assets/future.jpg" alt="manufacturing industries" class="img-fluid">
                    </div>
                </div>
                <p class="fontsize16 white proximanova-white pl-3 pr-3 mt-3">{{future3}}</p>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "eLearning | A Complete Guide on E-Learning Industry | Cognaxtech",
            meta: [
                { name: 'description', content:  'E-Learning: Importance of eLearning, Benefits of eLearning, how is eLearning used in education, how is online learning going to look like in the future and Many More!'},
                { name : 'keywords', content : 'eLearning, E-Learning, eLearning industry, what is E-learning, Importance of E-learning, Benefits of eLearning, adult eLearning, corporate eLearning.'}
            ]
        }
    }, 
    data() {
        return {
            description1 : 'E-learning, also referred to as online learning or electronic learning, adds knowledge through electronic technologies and media. In simple language, e-learning is defined as “learning that is encouraged electronically”. Typically, e-learning is shown on the Internet, where students can access their learning materials online at any place and time. E-Learning most frequently takes place in the form of online courses, online degrees, or online programs.',
            importanceList : [
                'E-learning has been introduced to empower learners to get primary schooling and enhance skills. Also, they can obtain a degree certificate without actually attending school or university or any other institution.',
                'It is an excellent source of earning for tutors, wherein they can teach from anywhere in their preferred time.',
                'Applying e-learning to all levels of schooling helped ensure students grasp the lessons adequately at a faster pace.',
                'According to psychology, the audio-visual teaching method leads to a disciplined learning environment. There are effective tutor and student engagements.',
                'One of the importance of e-learning in education is that tutors and participants can develop advanced learning skills. For example, creating and selling ebooks is one such improvement.',
                'E-learning has brought learners, tutors, experts, practitioners, and other interest groups to one place. Therefore, it is a good practice of sharing knowledge followed through different online platforms. This is important as the competition is rising and the world is growing. Hence, short information helps in the better growth of an individual.'
            ],
            benefitsList : [
                'Online Learning can accommodate everyone’s needs.',
                'Classes can be taken from any place and when students or tutors prefer.',
                'It offers complete, updated content, and accessibility is open, secure, and uninterrupted. E-Learning lets you sync with everyday learners and update with the current trends.',
                'It ensures quick delivery of lessons. Traditional classrooms involve some or kind of delay. Whereas eLearning provides expeditious and exclusive delivery of lessons. There is no procrastinator in eLearning. It is a quick way of Learning!',
                'The scalability of Learning, content, and duration that is taken can be reasonably measured. It is valuable to those who feel worried and disconnected in batches. It helps one learn without giving up the comforts of the environment. You are at convenience with Character of exposure, intakes, results, and coordination is highly ranked in E-Learning as it allows teachers a higher degree of coverage to deliver the content regularly. This ensures Consistency in Learning.',
                'The method is made available at much-reduced costs. The volume of E-Learning education is short and does not need much cost. The extended training period, infrastructure, stationery, travel expenses, etc., is reduced.',
                'The significance of the shared or imparted knowledge and learning is high and powerful. It makes data easy to learn and absorb. The Audio-Visuals help in remembering details for a longer time. Also, the techniques that tutors prepare are well-planned.',
                'Mobility secured is a very comfortable and affordable option. Taking revision and bringing different hands-on courses is not that simple in traditional classes. Intractable to that, missed lessons can always be retaken online. This makes it more accessible for tutors to impart the correct information.',
                'It promotes a self-paced learning process.',
                'Tutors have various options to teach from, such as creating recorded lessons, conducting short webinars, and selling courses with yearly packages. Hence, there are multiple options for making a side income or even a regular income.'
            ],
            howElearning : "E-Learning has extensive benefits across all sectors of society, and the general examples of e-learning used effectively are great.",
            adultElearning : "For adults, online learning often solves the numerous challenges adult learners face during their studies. Online learning allows them to progress at their own pace, submit assignments and take assessments at times best suited for them. This flexibility is especially beneficial for adult learners because they are usually forced to balance employment, family duties, and online learning altogether.",
            carporateEleaning1 : "On the other hand, companies use e-learning to boost their employees' knowledge, skills, and overall productivity while cutting down on the costs associated with employee training. Successful companies that have utilized e-learning include Toyota, Shell, PayPal, Lyft, and others.",
            carporateEleaning2 : "On the other hand, e-learning has become a helpful method for job seekers and unemployed people to boost their resumes and develop new skills in fields they are most interested in. Their online learning courses are available for virtually any career direction. Still, even 'career track' types of online training programs often come with a guaranteed job proposal for all graduates.",
            onlineColleges : "For educational institutions, e-learning brings possibly the most potential benefits of all. Many accredited online universities already offer online degree programs, and more of them will start to accomplish so in the upcoming years. E-Learning degrees enable universities to accept considerably more students than they would have otherwise been due to space and working staff constraints. With e-learning, universities have the chance to become more international than earlier. With increased amounts of admitted students and reduced costs, educational institutions that can adequately adapt to the standards of Internet learning will undoubtedly see increased profitability.",
            future1 : "The future of online learning will resume seeing exponential growth. As more other educational institutions, corporations, and online learners worldwide start to recognize the significance of online learning, its role in teaching will only continue to rise. Online learning already has multiple uses in education, and its future roles in education will be huge. The plans of the most successful educational institutions globally have already acknowledged that online learning can change people, knowledge, skills, and performance. Other educational institutions will likely follow the case shortly rather than later.",
            future2 : "However, we must not get ahead of ourselves. While online education is undoubtedly an exciting world to be in, many students who are uncomfortable with online learning still prefer the traditional live, in-person teaching methods they are used to. All students have unique learning styles, and online learning will likely never be a one-size-fits-all solution to education.",
            future3 : "With that being said, there’s no doubt that we are in the beginning phase of a new era in education. What is e-learning right now will pale compared to what is online learning in the years to come."
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .7), rgba(31,31,31,.7)), url('../../assets/elearning.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 100px 0;
}
/* section2 related styles */
.section2{
    padding-top: 6% !important;
    padding-bottom: 6% !important;
    /* background-color: white; */
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>