<template>
    <div class="edgeAi">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-4">
                        <h1 class="fontsize40 white proximanova-semibold">Artificial Intelligence across Industries</h1>
                    </div>
                </div>
                <div class="col-12 text-left mt-3">
                    <p class="fontsize16 white proximanova-white mt-3">{{description1}}</p>
                    <p class="fontsize16 white proximanova-white mt-3">{{description2}}</p>
                    <p class="fontsize16 white proximanova-white mt-3">{{description3}}</p>
                    <p class="fontsize16 white proximanova-white mt-3">{{description4}}</p>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-left">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <h3 class="fontsize28 white proximanova-semibold text-left">Technology with Creativity </h3>
                        <p class="fontsize16 white proximanova-white mt-5">Using the digitally created data for a leap in productivity</p>
                        <p class="fontsize16 white proximanova-white mt-3">With the digital transformation of industry, more and more data is generated that great offer potential.</p>
                        <p class="fontsize16 white proximanova-white mt-3">{{technologywithcreativity1}}</p>
                        <p class="fontsize16 white proximanova-white mt-3">{{technologywithcreativity2}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-left">
                        <div class="borderbottomwhite width100 mb-3"></div>
                        <h2 class="fontsize30 white proximanova-semibold text-left">Here are some of the significant AI applications across major industries</h2>
                        <div class="col-lg-12 pl-0 pr-0 d-lg-flex mt-5">
                            <div class="col-lg-7 pl-0 pr-0">
                                <div class="d-flex">
                                    <img src="../../assets/right_arrow.png" alt="right arrow" class="img-fluid" width="30px" height="30px">
                                    <h5 class="fontsize24 white proximanova-semibold text-left my-auto ml-3">Manufacturing Industry </h5>
                                </div>
                                <p class="fontsize16 white proximanova-white mt-3">{{manufacturingIndustry1}}</p>
                                <img src="../../assets/manufacturingIndustries.png" alt="manufacturing industries" class="img-fluid d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                                <p class="fontsize16 white proximanova-white mt-3">{{manufacturingIndustry2}}</p>
                            </div>
                            <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                                <img src="../../assets/manufacturingIndustries.png" alt="manufacturing industries" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-lg-12 pl-0 pr-0 d-lg-flex mt-5">
                            <div class="col-lg-7 pl-0 pr-0">
                                <div class="d-flex">
                                    <img src="../../assets/right_arrow.png" alt="right arrow" class="img-fluid" width="30px" height="30px">
                                    <h5 class="fontsize24 white proximanova-semibold text-left my-auto ml-3">Retail & E-Commerce</h5>
                                </div>
                                <p class="fontsize16 white proximanova-white mt-3">{{retail1}}</p>
                                <img src="../../assets/retail_ecommerce.jpg" alt="manufacturing industries" class="img-fluid d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                                <p class="fontsize16 white proximanova-white mt-3">{{retail2}}</p>
                                <p class="fontsize16 white proximanova-white mt-3">{{retail3}}</p>
                            </div>
                            <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                                <img src="../../assets/retail_ecommerce.jpg" alt="manufacturing industries" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-lg-12 pl-0 pr-0 d-lg-flex mt-5">
                            <div class="col-lg-7 pl-0 pr-0">
                                <div class="d-flex">
                                    <img src="../../assets/right_arrow.png" alt="right arrow" class="" width="30px" height="30px">
                                    <h5 class="fontsize24 white proximanova-semibold text-left my-auto ml-3">Banking and Financial Services </h5>
                                </div>
                                <p class="fontsize16 white proximanova-white mt-3">{{banking1}}</p>
                                <img src="../../assets/banking.jpg" alt="manufacturing industries" class="img-fluid d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                                <p class="fontsize16 white proximanova-white mt-3">{{banking2}}</p>
                            </div>
                            <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                                <img src="../../assets/banking.jpg" alt="manufacturing industries" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-lg-12 pl-0 pr-0 d-lg-flex mt-5">
                            <div class="col-lg-7 pl-0 pr-0">
                                <div class="d-flex">
                                    <img src="../../assets/right_arrow.png" alt="right arrow" class="" width="30px" height="30px">
                                    <h5 class="fontsize24 white proximanova-semibold text-left my-auto ml-3">Healthcare</h5>
                                </div>
                                <p class="fontsize16 white proximanova-white mt-3">{{healthcare1}}</p>
                                <img src="../../assets/healthcare.jpg" alt="manufacturing industries" class="img-fluid d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                                <p class="fontsize16 white proximanova-white mt-3">{{healthcare2}}</p>
                            </div>
                            <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                                <img src="../../assets/healthcare.jpg" alt="manufacturing industries" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-lg-12 pl-0 pr-0 d-lg-flex mt-5">
                            <div class="col-lg-7 pl-0 pr-0">
                                <div class="d-flex">
                                    <img src="../../assets/right_arrow.png" alt="right arrow" class="" width="30px" height="30px">
                                    <h5 class="fontsize24 white proximanova-semibold text-left my-auto ml-3">Logistics and Transportation</h5>
                                </div>
                                <p class="fontsize16 white proximanova-white mt-3">{{logistics1}}</p>
                                <img src="../../assets/logistics.jpg" alt="manufacturing industries" class="img-fluid d-block d-lg-none col-md-8 pl-0 pr-0 mx-auto">
                                <p class="fontsize16 white proximanova-white mt-3">{{logistics2}}</p>
                            </div>
                            <div class="col-md-8 mx-auto col-lg-5 my-auto d-none d-lg-block">
                                <img src="../../assets/logistics.jpg" alt="manufacturing industries" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto text-center">
                        <div class="borderbottomwhite width100 mb-3 mx-auto"></div>
                        <h5 class="fontsize28 white proximanova-semibold">Conclusion</h5>
                        <p class="fontsize16 white proximanova-light mt-3">{{conclusion}}</p>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "AI | Complete Guide to Artificial Intelligence across Industry ",
            meta: [
                { name: 'description', content:  'Learn about Artificial Intelligence across major Industry such as Manufacturing Industry, Retail & E-commerce, Banking & Financial services, Healthcare and Many more! '},
                { name : 'keywords', content : 'Artificial intelligence, AI, Artificial intelligence in industry, Artificial intelligence across industry, Impact of AI across industries, what is artificial industry.'}
            ]
        }
    }, 
    data() {
        return {
            description1 : 'Although Artificial Intelligence (AI) has been an academic and scientific domain since the 1950s, AI technology has achieved much traction in the last few years. It’s unnecessary to mention that the current wave in AI research, investment and real business applications is remarkable.',
            description2 : "Artificial intelligence (AI) constantly makes inroads into domains previously reserved to humans. Robots support workers in the manufacturing sector; digital associates automate office tasks; intelligent appliances order food based on owner’s preferences or control lighting and temperature in the home in preparation of their arrival. Increasingly refined algorithms have the potential to help address some of humanity's most significant challenges. They also bring about several risks and threats that businesses, governments and policymakers need to understand and tackle carefully.",
            description3 : "This white paper imposes the scene for learning where AI stands today and the view for the next 5 to 10 years. Taking an industrial perspective, it concerns in more detail: smart homes, smart manufacturing, innovative transportation/self-driving vehicles, and the energy sector.",
            description4 : "It covers current technological capabilities and provides a detailed description of some of the significant existing and future challenges related to safety, security, privacy, trust and ethics that AI will have to address at the international level. AI will become one of the core technologies across many industries, and standardization will play a critical role in shaping its future.",
            technologywithcreativity1 : "Artificial Intelligence (AI) and Machine Learning allow engineers and data scientists to structure, research and assess the huge volume of data. The application techniques vary across the lifecycle: Smart advice, productive design, anomaly detection, and preventative maintenance optimize the way and pace with which products are designed and produced.",
            technologywithcreativity2 : "To utilize the modern grade AI applications in a dependable and value-adding way, they should cooperate seamlessly with software and automation and compare IT infrastructure. Therefore, collaboration and open ecosystems are key to leveraging the great possibility of these technologies.",
            manufacturingIndustry1 : "It is beyond doubt that the manufacturing industry is leading the way in applying and adopting AI technology. AI is operated across several lines and layers of operations in manufacturing, from crew planning to product design, enhancing efficiency, product quality, and employee safety.",
            manufacturingIndustry2 : "The manufacturing process is highly complex and involves many interconnected operations. Artificial intelligence applications are nearly as multi-faceted as the production method itself in manufacturing. In product design, AI technology enables the creation of prototypes and testing them before launching them into the presentation. The production process is facilitated by robots working alongside humans and handling hazardous tasks requiring physical labour. Modern AI-driven ERP systems accumulate and analyze many metrics about every aspect of the manufacturing process and offer more helpful resource management, planning, and quality control. Associated with the Internet of Things technology (IoT), AI accounts for successful predictive maintenance by monitoring the state of assets and tools.",
            retail1 : "Retail and E-commerce is possibly the best space where the application of AI is the most visible to end-users. As a competitive space, retail organizations continuously look for strategies to find ways in consumer behaviour and align their plan to outsmart their competitors.",
            retail2 : "Inarguably, AI applications in retail are some of the most unique and illustrative. AI has already completely transformed the customer experience no matter what we shop for. Behavioural analytics is an AI tool used by online and offline retailers to detect client behaviour and squeeze their offerings to increase sales. Recommendation engines are helping clients select the products they would value based on their previous choices.",
            retail3 : "The job of shop associates has also experienced a considerable transformation. Human intervention is required in only particular situations, with chatbots answering the most frequent and routine questions.",
            banking1 : "In the financial industry, AI has accounted for the emergence of a new segment. Associated with artificial intelligence, financial services are now leveraging data analytics to access customers credibility accurately, deliver insurance, offer financial advice, and detect fraud. AI in banking simplifies and accelerates the process of loan origination and management. Bank software applications available in desktop and mobile versions have eliminated the necessity of going to a physical bank to conduct balance checks and money transfers. AI credit scoring helps customers track and evaluate their credit rating and see what steps to improve it.",
            banking2 : "Likewise, AI-based chatbots are being deployed in the Insurance sector to enhance the customer experience and create insurance strategies and products based on customers data. AI-based software has also significantly reduced the claim processing time, therefore helping both the insurance companies and customers.",
            healthcare1 : "The digital healthcare transformation is driven by the beginning of AI, among other factors. Artificial intelligence in healthcare and its ability to process large amounts of data and detect slight changes in any process make healthcare technology's future look brighter than ever. Today, artificial intelligence in medical diagnosis is helping detect diseases before they display and curb them at early stages. Driven by AI, data analytics help accelerate and drive new drug discovery.",
            healthcare2 : "Technology giants like Microsoft, Google, Apple, and IBM in the healthcare sector hold significant priority. AI is currently being applied for a wide range of healthcare benefits, including data mining for identifying patterns and then taking out the more accurate diagnosis and treatment of medical disorders, medical imaging, medication management, drug discovery and robotic surgery.",
            logistics1 : "AI is also at the forefront of ground-breaking changes in the logistics and transportation industry. AI in logistics leverages data analytics for warehousing, fleet management, and route planning. It also involves using robots to free warehouse personnel from hard physical labour. The use of artificial intelligence in transport also accounts for the emergence of self-driving vehicles. AI in transportation is also successfully used for intelligent city management to manage traffic, avoid congestion, and make the roads safer for drivers and pedestrians.",
            logistics2 : "The logistics and transportation industry is on the threshold of an AI-infused process. The use of machine learning and predictive analytics has already changed supply chain management, creating a seamless procedure. Many warehouses use AI-powered robots for sorting and packaging consequences in warehouses. Likewise, AI algorithms are also increasingly used to find the fastest shipment route and support the last-mile delivery.",
            conclusion : "This summary of the latest artificial intelligence applications shows how AI transforms, how major industries operate and apply AI. More reasonable accuracy, efficiency, acceleration of essential processes, cost reduction, and enhanced quality is what AI gets to the table if executed thoughtfully and considering the specifics of a particular company and industry. "
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .7), rgba(31,31,31,.7)), url('../../assets/artificialIntelligence.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 200px 0;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>