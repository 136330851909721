<template>
    <div class="">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">WEB APPLICATION Development </h1>
                    </div>
                    <p class="proximanova fontsize24 white body-copy text-left">Enriching business capabilities with custom web applications designed to meet business challenges.</p>
                </div>
                <div class="d-flex col-12 pl-0 pr-0 flex-wrap">
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/database.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">TECHNOLOGY</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{technology}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/clients.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">CLIENTS</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Clients}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/experince.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">EXPERIENCE</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Experience}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/services.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">SERVICES</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Services }}</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-12 my-auto">
                        <div class="col-lg-12 pl-0 pr-0">
                            <p class="proximanova-semibold fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse1}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse2}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse3}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse4}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section3 aboutCompanyBG">
            <div class="container">
                <div class="borderbottomwhite width100 ml-lg-3 ml-2 mb-3"></div>
                <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                    <h2 class="fontsize28 white proximanova-semibold">Custom Web Application Development </h2><br>
                </div>
                <div class="d-flex col-12 pl-0 pr-0 flex-wrap">
                    <div class="col-lg-4 col-md-6 mt-3 text-left d-flex flex-column">
                        <img src="../../assets/engaging.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h2 class="fontsize20 white proximanova-semibold mt-2">Engaging Customer Experience </h2>
                        <span class="fontsize16 white proximanova-white mt-3">{{engaging}}</span>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-3 text-left d-flex flex-column">
                        <img src="../../assets/creating.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h2 class="fontsize20 white proximanova-semibold mt-2">Creating Business Innovation</h2>
                        <span class="fontsize16 white proximanova-white mt-3">{{creating}}</span>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-3 text-left d-flex flex-column">
                        <img src="../../assets/streamline.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h2 class="fontsize20 white proximanova-semibold mt-2">Streamline Business with Optimized Processes </h2>
                        <span class="fontsize16 white proximanova-white mt-3">{{streamline}}</span>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "Web Application Development | What is web Application Development ",
            meta: [
                { name: 'description', content:  'Cognaxtech is a top web application development company, We focus on custom web application development, Engaging customer experience, Creating business Innovation.'},
                { name : 'keywords', content : 'Web application development, web application development services, what is web application development, web application development process, how to develop a web application.'}
            ]
        }
    },
    data() {
        return {
            aboutCourse1 : 'The way businesses depend on software has undergone a prominent shift as more emphasis is given on tools that resolve day-to-day business challenges and support productivity. Earlier, SaaS was the key model that organizations adopted, but that is no longer true considering increased costs, limited customizations, and vague data ownership terms. Moreover, as business workflows become more elaborate, traditional software that can solve business needs without working around the limitations of off-the-shelf software becomes necessary.',
            aboutCourse2 : 'Custom web applications address the limitations of established SaaS tools by giving businesses more efficiency, accessibility, customization, and security that aids in solving individual business challenges.',
            aboutCourse3 : 'Having a set of web applications customized to meet individual business prerequisites can act as valuable assets to an organization that promotes growth in valuation and protects innovation in the long term.',
            aboutCourse4 : 'Our development team observes each client’s needs and builds powerful web applications with scalable features. In this way, businesses can reasonably simplify their daily operations and procedures to fulfill critical challenges and reach targeted goals.',
            technology : 'We enable our clients with adaptive web applications built utilizing open-source and exclusive web technologies and practices to improve complex business workflows.',
            Clients : 'Our client base across various industries has successfully utilized our rich web expertise to launch highly scalable web applications.',
            Experience : 'We have cut out a specialty in web application development alongside valued consulting and analysis solutions to serve growing businesses and industry pioneers like Sony, Johnson & Johnson, NEC, etc.',
            Services : 'At Cognax tech, we focus on combining technology and consulting to create innovative web applications delivering secure, scalable, and automatic usefulness to your end-users.',
            engaging : 'We help organizations achieve operational efficiency and independence through our natural custom web applications. Our innovation stack includes the newest technologies like PostgreSQL, Python, MongoDB, Angular, React, Node.js, etc.',
            creating : "We progress toward efficiency in our custom web applications by exposing them to obtained design standards and testing. In this way, we deliver our client's reliable web applications that can be fully operational from day one of implementation. Agile practices follow-up ensures that the projects are provided to our clients within the stipulated time and budget. ",
            streamline : "Maximizing capability is one of our critical needs with the web applications that we create for our clients. Cognax tech's services extend globally with many satisfied clients who use our custom web applications to tackle their business challenges and needs. Talk to our solution consultants about your requirement to help bring your ideas to life.",
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .9), rgba(31,31,31,.9)), url('../../assets/webappdevelopment.png');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 200px 0;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.aboutCompanyBG{
    background-image: linear-gradient(rgba(44, 62, 80, .9), rgba(44, 62, 80, .9)), url('../../assets/webappdevelopment1.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>