<template>
    <div class="">
        <section class="section1 bgimage1" id="section1">
            <div class="container d-lg-flex mt-5">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold mt-5" id="mainHeading">{{mainHeading}}</h1>
                    </div>
                    <!-- <span class="fontsize24 white proximanova text-left">{{subHeading}}</span> -->
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="container">
                <div class="d-flex">
                    <div class="backgroundColor6 width10 height100 borderadius16 ml-lg-3 mb-3 ml-2"></div>
                    <div class="div-block-41 d-flex flex-column text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                        <h2 class="fontsize28 white proximanova-bold">Hello,</h2>
                        <span class="fontsize28 white proximanova-semibold">how can I help you?</span>
                        <span class="fontsize18 white proximanova mt-2">Hi, I am interested in...</span>
                    </div>
                </div>
                <div class="">
                    <div class="col-lg-12 my-auto">
                        <div class="col-12 d-flex flex-wrap">
                        <span class="borderwhite pl-3 pr-3 p-1 mt-3 ml-2 borderadius16 pointer serviceItemHover" v-for="(service, index) in listItems" :key="index" @click="NavtoCourse(service)">
                            <p class="proximanova fontsize16 white body-copy  text-center mb-0 serviceItem">{{service.courseName}}</p>
                        </span>
                        </div>
                    </div>
                    <div class="col-lg-12 d-flex flex-wrap justify-content-between mt-5 pt-5">
                        <div class="col-md-6 blogcard pointer" @click="NavToBlog('AI')">
                            <div class="blogImg">
                                <img src="../assets/artificialIntelligence11.jpg" class="img-fluid " alt="artificial intelligence">
                            </div>
                            <div class="blogTitle p-4 text-left">
                                <div class="borderleftwhite mb-4">
                                    <span class="fontsize24 white proximanova-semibold pl-3">Artificial Intelligence</span>
                                </div>
                                <span class="fontsize18 white proximanova-light">Using the digitally created data for a leap in productivity With the digital transformation of industry...</span>
                            </div>
                        </div>
                        <div class="col-md-6 blogcard pointer" @click="NavToBlog('EdgeAI')">
                            <div class="blogImg">
                                <img src="../assets/artificialIntelligenceEdgeAI1.jpg" class="img-fluid " alt="artificial intelligence">
                            </div>
                            <div class="blogTitle p-4 text-left">
                                <div class="borderleftwhite mb-4">
                                    <span  class="fontsize24 white proximanova-semibold pl-3">Edge AI</span>
                                </div>
                                <span  class="fontsize18 white proximanova-light">With the volatile growth of mobile computing and Internet of Things (IoT) applications, billions of mobile...</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="rightArrow shadow">
                    <img src="../assets/right_arrow.png" class="img-fluid pointer" @click="moveToRight" width="40px" height="40px" alt="right arrow" title="Scroll right to view more articles">
                    </div> -->
                    <div id="horizArticle" class="col-lg-12 d-flex flex-nowrap justify-content-between articlesSection mt-5 pt-5">
                        <div class="col-12 col-md-7 col-lg-5 blogcard pointer" @click="NavToArticle(article)" v-for="(article, index) in articles" :key="index">
                            <div class="blogImg">
                                <img src="../assets/aboutUsMobility.jpg" v-if="index == 0" class="img-fluid " alt="artificial intelligence">  
                                <img src="../assets/elearning.jpg" v-if="index == 1" class="img-fluid " alt="artificial intelligence">                            
                                <img src="../assets/lifeSciences.jpg" v-if="index == 2" class="img-fluid " alt="artificial intelligence"> 
                                <img src="../assets/aiautomobile.jpg" v-if="index == 3" class="img-fluid " alt="artificial intelligence"> 
                                <img src="../assets/lifeinsurence.jpg" v-if="index == 4" class="img-fluid " alt="artificial intelligence">                            
                            </div>
                            <div class="blogTitle p-4 text-left">
                                <div class="borderleftwhite mb-4 p-2">
                                    <span class="fontsize24px white proximanova-semibold">{{article.title}}</span>
                                </div>
                                <span class="fontsize18 white proximanova-light">{{article.description}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="mx-auto">
                        <div class="mx-auto">
                            <img src="../assets/leftarrow_black.png" width="40px" height="40px"  @click="moveToLeft"  class="arrowbtn mr-2" alt="left arrow">
                            <img src="../assets/rightarrow_black.png" width="40px" height="40px"  @click="moveToRight"  class="arrowbtn ml-2" alt="left arrow">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 aboutCompanyBG">
            <div class="container">
                <div class="borderbottomwhite width100 ml-lg-3 mb-3 ml-2"></div>
                <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                    <h2 class="fontsize28 white proximanova-bold">About Our Company</h2><br>
                </div>
                <div class="d-lg-flex">
                    <div class="col-lg-9 my-auto text-left">
                        <div class="col-lg-12 pl-0 pr-0 mb-5">
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutTheCompany}}</p>
                        </div>
                        <span class="proximanova-bold fontsize20 white mt-5 pointer" @click="NavToAboutUsBusiness">Show More ...</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="section3">
            <div class="container">
                <div class="borderbottomwhite width100 ml-lg-3 ml-2 mb-3"></div>
                <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                    <h2 class="fontsize28 white proximanova-semibold">WHO WE ARE</h2><br>
                </div>
                <div class="d-lg-flex">
                    <div class="col-lg-5 my-auto">
                        <img src="../assets/aboutCompany1.jpg" class="img-fluid abtCompimg" alt="mobile application development">
                    </div>
                    <div class="col-lg-7 my-auto text-left">
                        <div class="col-lg-12 pl-1 pr-1 mb-5">
                            <p class="proximanova-light fontsize20 white body-copy text-left">{{whatWeDo1}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left">{{whatWeDo2}}</p>
                        </div>
                        <span class="proximanova-bold fontsize20 white mt-5 pointer ml-4" @click="NavToAboutUs">Show More ...</span>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from './footer.vue';
import router from '../router'
export default {
    data() {
        return {
            mainHeading : 'Technology is going to disrupt the future of work, perhaps sooner than we thoughts.',
            subHeading : 'Share your ideas to get your mobile apps in the market.',
            aboutTheCompany : 'Cognax tech is an up-and-coming software development company specializing in providing the best to its clients within their budget. Our company has carved a niche by offering quality services to our clients worldwide. With an unmatched combination of comprehensive business domain understanding and technical know-how, multiplied by profound knowledge of the innovative industry trends and technological innovations – Careertuner plans and executes quality-oriented software development and delivers custom-made solutions that are sure to fulfill your needs.',
            whatWeDo1 : 'We are a highly ambitious and dynamic development, and staffing company with our business commenced in the year. From that point in time, our goal invariably has been to exploit the power of connections of our patrons, clients, and employees. The start of our business came off with a marvelous vision, and we label it as Network Computing. It depends on one basic fundamental: the more massive the set of connections turns up, the more powerful it is.',
            whatWeDo2 : 'Because of this, we further a milieu of consideration: every distinctive viewpoint and a set of experiences make the Network more powerful. We plan to outfit that strong point to work for yourself and bring you.',
            listItems : [
                {courseName : 'Internet Of Things'},
                {courseName : 'Web Application Development'},
                {courseName : 'Business Technology Consulting'},
                {courseName : 'Cloud Computing Application'},
                {courseName : 'IOS Application Development'},
                {courseName : 'Android Application Development'},
                {courseName : 'Hybrid Application Development'},
                {courseName : 'Recruiting'}
            ],
            articles : [
                {title : 'Digitalization in Mobility', description : 'We are becoming more connected with the technologies surrounding us since digitalization...'},
                {title : 'E-Learning', description : 'E-learning, also referred to as online learning or electronic learning, adds knowledge...'},
                {title : 'Life Sciences Industry', description : "Every day, we see the world continuously changing. Whether it's advanced in technology..."},
                {title : 'AI is transforming the automobile industry', description : 'Artificial intelligence is altering every factor of the automobile industry...'},
                {title : 'Top Technology trends in the life insurance sector', description : 'Advanced technology has already evolved into an essential part of the insurance industry...'},
            ]
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0);
        this.animatedSection1();
    },
    methods : {
        animatedSection1(){
            var imageHead = document.getElementById('section1')
            var heading = document.getElementById('mainHeading')
            var images = [
                {mainHeading : 'Leading the next wave of edge and AI Innovations.', subHeading : 'Share your ideas to get your mobile apps in the market.', class : 'bgimage2'},
                {mainHeading : 'Digital Transformation will still be key for IT using AI, Cloud, IoT and Many new technologies.', subHeading : 'Share your ideas to get your mobile apps in the market.', class : 'bgimage3'},
                {mainHeading : 'Technology is going to disrupt the future of work, perhaps sooner than we thoughts.', subHeading : 'Share your ideas to get your mobile apps in the market.', class : 'bgimage1'},
            ]
            var i = 0;
            setInterval(function() {
                // imageHead.style.backgroundImage = "url(" + images[i].bgImage + ")";
                if(i > 0){
                    imageHead.classList.remove(images[i-1].class)
                    imageHead.classList.remove('bgimage3')
                }
                imageHead.classList.add(images[i].class)
                heading.innerHTML = images[i].mainHeading;
                i = i + 1;
                
                if (i == images.length) {
                    imageHead.classList.remove('bgimage1')
                    imageHead.classList.remove('bgimage2')
                    i =  0;
                }
            }, 5000);
        },
        moveToRight() {
            document.getElementById('horizArticle').scrollLeft += 300;
        },
        moveToLeft() {
            document.getElementById('horizArticle').scrollLeft -= 300;
        },
        NavtoCourse(course){
            if(course.courseName === 'Internet Of Things') {
                router.push({
                    name : 'iot'
                })
            }else if(course.courseName === 'Web Application Development') {
                router.push({
                    name : 'webApp'
                })
            }else if(course.courseName === 'Business Technology Consulting') {
                router.push({
                    name : 'businessTechnology'
                })
            }else if(course.courseName === 'Cloud Computing Application') {
                router.push({
                    name : 'cloudComputing'
                })
            }else if(course.courseName === 'IOS Application Development') {
                router.push({
                    name : 'iosApplication'
                })
            }else if(course.courseName === 'Android Application Development') {
                router.push({
                    name : 'androidApplication'
                })
            }else if(course.courseName === 'Hybrid Application Development') {
                router.push({
                    name : 'hybridApplication'
                })
            }else if(course.courseName === 'Recruiting') {
                router.push({
                    name : 'recruiting'
                })
            }
        },
        NavToBlog(blog){
            if(blog == 'AI'){
                router.push({
                    name : 'artificialIntelligence'
                })
            }else{
                router.push({
                    name : 'edgeAI'
                })
            }
        },
        NavToArticle(article){
            if(article.title == 'Digitalization in Mobility'){
                router.push({
                    name : 'digitalizationInMobility'
                })
            }else if(article.title == 'E-Learning'){
                router.push({
                    name : 'elearning'
                })
            }else if(article.title == 'Life Sciences Industry'){
                router.push({
                    name : 'lifeSciences'
                })
            }else if(article.title == 'AI is transforming the automobile industry'){
                router.push({
                    name : 'aiInAutomobileIndustry'
                })
            }else if(article.title == 'Top Technology trends in the life insurance sector'){
                router.push({
                    name : 'toptechnology'
                })
            }
        },
        NavToAboutUsBusiness() {
            router.push({
                name : 'aboutUsBusiness'
            })
        },
        NavToAboutUs() {
            router.push({
                name : 'aboutUs'
            })
        }
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 13% !important;
    padding-bottom: 9% !important;
    color:white;
    /* background-image: linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.4)), url("") !important; */
    background-image: linear-gradient(rgba(31, 31, 31, .4), rgba(31,31,31,.4)), url('../assets/LandingSlide3.jpg') !important;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
    /* background-image: linear-gradient(#eeeeee, white); */
}
#section1 {
    height: 700px;
}
.mblsecimg{
    border-radius: 200px 0;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.articlesSection{
    overflow-x: auto;
    scroll-behavior: smooth;
}
.articlesSection::-webkit-scrollbar {
  display: none;
}
.aboutCompanyBG{
    background-image:  url('../assets/LandingSlide1.jpg') !important;
   background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.blogImg {
    z-index: 3;
    position: relative;
}
.blogTitle {
    position: relative;
    /* background-color: #1c2027 !important; */
    background-color: black;
    z-index: 4;
}
.blogcard:hover .blogTitle {
    margin-top: -25px;
    color: white;
    transition-duration: 0.3s;
    opacity: 1 !important;
}
.blogcard{
    transition-duration: 0.3s;
}
.blogcard:hover {
    opacity: .8;
}
.fontsize24px{
    font-size: 20px;
}
.rightArrow{
    position: absolute;
    right: 0;
    margin-right: 14%;
    margin-top: 15%;
    z-index: 4;
}
.arrowbtn {
    /* background: white; */
    border-radius: 50%;
    cursor: pointer;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    background-color: black;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}

.bgimage1{
    /* background-image:  url('../assets/LandingSlide3.jpg') !important; */
    background-image: linear-gradient(rgba(31, 31, 31, .4), rgba(31,31,31,.4)), url('../assets/LandingSlide3.jpg') !important;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.bgimage2{
    background-image:  url('../assets/LandingSlide6.jpg') !important;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.bgimage3{
    /* background-image: linear-gradient(rgba(31, 31, 31, .8), rgba(31,31,31,.8)), url('../assets/ios.jpg') !important; */
    background-image:  url('../assets/LandingSlide2.jpg') !important;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
@media screen and (max-width : 500px) {
    .fontsize24px{
        font-size: 16px;
    }
    .rightArrow{
        margin-top: 50%;
    }
}
</style>