<template>
    <div class="">
        <section class="section1">
            <div class="container">
                <div class="col-lg-12 my-auto text-left">
                    <div class="borderleftwhite div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2 mb-4 mt-5">
                        <h1 class="fontsize40 white proximanova-semibold">Business Technology Consulting</h1>
                    </div>
                    <p class="proximanova fontsize24 white body-copy text-left">IT Strategy development and consulting services to enhance business productivity.</p>
                </div>
                <div class="d-flex col-12 pl-0 pr-0 flex-wrap">
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/database.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">TECHNOLOGY</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{technology}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/clients.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">CLIENTS</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Clients}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/experince.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">EXPERIENCE</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Experience}}</span>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-center d-flex flex-column">
                        <img src="../../assets/services.svg" class="img-fluid mx-auto" width="50px" height="50px" alt="technologies">
                        <span class="fontsize24 white proximanova-semibold mt-2">SERVICES</span>
                        <span class="fontsize16 white proximanova-white mt-3">{{Services }}</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2 ">
            <div class="container">
                <div class="d-lg-flex">
                    <div class="col-lg-10 my-auto">
                        <div class="col-lg-12 pl-0 pr-0">
                            <p class="proximanova-semibold fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse1}}</p>
                            <p class="proximanova-light fontsize20 white body-copy text-left mt-4 mt-lg-0">{{aboutCourse2}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section3 aboutCompanyBG">
            <div class="container">
                <div class="borderbottomwhite width100 ml-lg-3 ml-2 mb-3"></div>
                <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3 pl-2 pr-2">
                    <h2 class="fontsize28 white proximanova-semibold">We offer Business Technology Consulting solutions in areas such as</h2><br>
                </div>
                <div class="d-flex col-12 pl-0 pr-0 flex-wrap">
                    <div class="col-lg-4 col-md-6 mt-3 text-left d-flex flex-column">
                        <img src="../../assets/engaging.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h2 class="fontsize20 white proximanova-semibold mt-2">Mechanical Point Solutions </h2>
                        <div class="mt-2">
                            <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="img-fluid" alt="tickmark">
                            <span class="fontsize16 white proximanova ml-2">Automated Verification</span>
                        </div>
                        <div class="mt-2">
                            <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="img-fluid" alt="tickmark">
                            <span class="fontsize16 white proximanova ml-2">Remote Diagnostics</span>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6 mt-3 text-left d-flex flex-column">
                        <img src="../../assets/creating.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h2 class="fontsize20 white proximanova-semibold mt-2">IT Strategy Consulting</h2>
                        <div class="mt-2">
                            <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="img-fluid" alt="tickmark">
                            <span class="fontsize16 white proximanova ml-2">Identify and validate new expansion opportunities</span>
                        </div>
                        <div class="mt-2">
                            <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="img-fluid" alt="tickmark">
                            <span class="fontsize16 white proximanova ml-2">Improve Application Development Efficiency</span>
                        </div>
                        <div class="mt-2">
                           <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="img-fluid" alt="tickmark">
                            <span class="fontsize16 white proximanova ml-2">Technology Adoption</span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-3 text-left d-flex flex-column">
                        <img src="../../assets/streamline.png" class="img-fluid" width="60px" height="60px" alt="technologies">
                        <h2 class="fontsize20 white proximanova-semibold mt-2">Infrastructure Migration</h2>
                        <div class="mt-2">
                            <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="img-fluid" alt="tickmark">
                            <span class="fontsize16 white proximanova ml-2">Strategic Alignment</span>
                        </div>
                        <div class="mt-2">
                            <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="img-fluid" alt="tickmark">
                            <span class="fontsize16 white proximanova ml-2">Process Innovation</span>
                        </div>
                        <div class="mt-2">
                            <img src="../../assets/courseTickMark_white.svg" width="20px" height="20px" class="img-fluid" alt="tickmark">
                            <span class="fontsize16 white proximanova ml-2">Product Innovation</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer class="footer"></Footer>
    </div>
</template>
<script>
// import { EventBus } from '@/main.js';
import Footer from '../../components/footer.vue';
export default {
    metaInfo() {
        return { 
            title: "IT Consulting | Information Technology (IT) Consulting | IT Firms",
            meta: [
                { name: 'description', content:  "IT consulting services are advisory services that help client assess different technology strategy's and align their technology strategies with their businesses."},
                { name : 'keywords', content : 'IT consulting, Information technology consulting, IT consulting services, what is IT consulting, Business consulting services, IT services.'}
            ]
        }
    },
    data() {
        return {
            aboutCourse1 : 'It becomes increasingly difficult to evaluate various technology options with an existing business solution. This is a big challenge, especially for a start-up firm or firm where technology is not the core competency. Cognax tech’s experienced business technology consultants will guide you with strategic advice on technology, helping you decide on advanced solutions that would be cost-effective and highly productive for your business process.',
            aboutCourse2 : 'Cognax tech helps you adopt the right IT Strategy for your business and craft simple solutions to drive your business objectives seamlessly. Moreover, our delivery practices enable us to adjust our solutions for your business objectives. With their share of knowledge in many industry verticals and expertise, our Analysts help in the efficient implementation of projects and create systems that bring value to your IT investments.',
            technology : 'Our vast expertise in technology and industry knowledge enables us to help organizations adopt the latest technology and business practices.',
            Clients : 'Our solutions and best practices empower enterprises like Sony, PWC, J&J, Vlocker, etc., to empower us with their projects. Our expertise secures risk reduction and enhanced efficiency.',
            Experience : 'Our technology professionals provide advice to solve business and technology problems for multinational clients ranging from Fortune 500 companies to start-ups and industry leaders.',
            Services : 'We provide the best-suited guidance to ensure that any digital transformation for the client’s business is in harmony with their business strategy.',
            engaging : 'We help organizations achieve operational efficiency and independence through our natural custom web applications. Our innovation stack includes the newest technologies like PostgreSQL, Python, MongoDB, Angular, React, Node.js, etc.',
            creating : "We progress toward efficiency in our custom web applications by exposing them to obtained design standards and testing. In this way, we deliver our client's reliable web applications that can be fully operational from day one of implementation. Agile practices follow-up ensures that the projects are provided to our clients within the stipulated time and budget. ",
            streamline : "Maximizing capability is one of our critical needs with the web applications that we create for our clients. Cognax tech's services extend globally with many satisfied clients who use our custom web applications to tackle their business challenges and needs. Talk to our solution consultants about your requirement to help bring your ideas to life.",
        }
    },
    components : {
        Footer
    },
    mounted() {
        window.scrollTo(0, 0)
        
    },
    methods : {
        
    }
}
</script>
<style lang="css" scoped>
/* section1 related styles */
.section1{
    padding-top: 12% !important;
    padding-bottom: 12% !important;
    color:white;
    background-image: linear-gradient(rgba(31, 31, 31, .88), rgba(31,31,31,.88)), url('../../assets/businessconsulting.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
.mblsecimg{
    border-radius: 200px 0;
}
/* section2 related styles */
.section2{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.abtCompimg{
    border-radius: 0 100px;
}
.serviceItemHover:hover{
    background-color: white !important;
}
.serviceItemHover:hover .serviceItem{
    color: #2c3e50 !important;
}
.aboutCompanyBG{
    background-image: linear-gradient(rgba(44, 62, 80, .9), rgba(44, 62, 80, .9)), url('../../assets/businessconsulting1.jpg');
    background-size: cover !important;
    -webkit-background-size: cover !important;
    overflow: hidden !important;
}
/* section3 related styles */
.section3{
    padding-top: 8% !important;
    padding-bottom: 8% !important;
}
.whatwedocard:hover {
    box-shadow: 0 10px 15px rgba(33,33,33,.4) !important;
}
.footer{
    /* background-color: rgb(43, 49, 56); */
    background-color: black;
}

</style>